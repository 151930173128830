@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(Nunito Regular), local(Nunito-Regular), url("https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(Nunito Regular), local(Nunito-Regular), url("https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaBTMnFcQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(Nunito SemiBold), local(Nunito-SemiBold), url("https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUb-vIWzgPDEtj.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(Nunito SemiBold), local(Nunito-SemiBold), url("https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevIWzgPDA.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(Nunito Bold), local(Nunito-Bold), url("https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOUb-vIWzgPDEtj.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(Nunito Bold), local(Nunito-Bold), url("https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOUYevIWzgPDA.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --menu-width: 180px;
  --top-space: 20px;
  --pad-x: 20px;
  --black: #313030;
  --white: #fff;
  --off-whiter: #fafafa;
  --off-white: #f8f7f7;
  --purple: #3f55ab;
  --purple-lighter: #3f55ab33;
  --green-darker: #2cb582;
  --green-dark: #40bf90;
  --green: #47c295;
  --green-light: #bae8d7;
  --green-lighter: #a2e6cd;
  --green-lightest: #ecf8f4;
  --red: #fe6558;
  --red-light: #f5b3ad;
  --red-dark: #fe2e1b;
  --red-darker: #e41401;
  --blue: #78bcd9;
  --blue-darker: #54aacf;
  --blue-light: #c7e7f4;
  --yellow: #ffcc80;
  --yellow-light: #ffdca8;
  --yellow-dark: #ffc266;
  --gray1: #ececec;
  --gray2: #dedede;
  --gray3: #b1b1b1;
  --gray4: #949494;
  --gray5: #828282;
  --gray6: #616161;
  --light-gray: #f3f2f2;
  --secondary-gray: #f3f3f1;
  --link-color: var(--green-darker);
  --background-color: var(--light-gray);
  --menu-background: var(--off-white);
  --regular: 400;
  --semibold: 500;
  --bold: 600;
  --content-width-medium: 850px;
  --content-width-large: 1150px;
  --section-spacing: 60px;
  --grid-column-gap: 40px;
  --wide-shadow: 0 1px 14px #0000000d;
  --tight-shadow: 0 1px 1px #0000000d;
  --card-shadow: 0 1px 14px #0000000d, 0 1px 1px #0000000d;
  --shadow-clickable: 0px .5px 1px #00000040;
  --editor-large-text: 28px;
  --editor-medium-text: 24px;
  --editor-normal-text: 18px;
  --editor-small-text: 14px;
  --index-block-variant-selector: 20;
  --index-dropdown: 50;
  --index-header: 2000;
  --index-menu: 2200;
  --index-notification: 2000;
  --index-quicklinks: 2500;
}

@media (width >= 1000px) {
  :root {
    --pad-x: 40px;
    --menu-width: 220px;
  }
}

@media (width >= 1400px) {
  :root {
    --pad-x: 60px;
    --menu-width: 260px;
  }
}

html, body {
  height: 100%;
  color: var(--black);
  background: var(--background-color);
  text-rendering: geometricprecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: Nunito, system-ui, sans-serif;
  line-height: 1.5;
}

#app {
  height: 100%;
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1;
}

h2, h3 {
  margin: 1.5em 0 1em;
}

p {
  margin: 1em 0;
}

ol, ul, li {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

pre {
  tab-size: 2;
}

strong {
  font-weight: var(--bold);
}

.ov64_a_BaseButton {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: text-bottom;
  box-sizing: border-box;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.ov64_a_BaseButton:focus {
  outline: none;
}

.ov64_a_BaseButton[disabled] {
  opacity: .3;
  pointer-events: none;
}

.ov64_a_Button:focus {
  animation: .15s ov64_a_button-click;
}

@keyframes ov64_a_button-click {
  0% {
    transform: scale(.95);
  }
}

.ov64_a_BaseButton--is-loading, .ov64_a_BaseButton--is-loading:focus {
  filter: saturate(.3) brightness(1.2);
  pointer-events: none;
  background-image: url("loading-dots.7946086f.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  animation: 2s infinite ov64_a_loading-fade;
}

@keyframes ov64_a_loading-fade {
  0%, 100% {
    opacity: .9;
  }

  50% {
    opacity: .7;
  }
}

.ov64_a_BaseButton > * {
  transition: opacity .15s;
}

.ov64_a_BaseButton--is-loading > * {
  opacity: 0;
}

.ov64_a_BaseButton[data-popover]:before {
  content: attr(data-popover);
  background: var(--black);
  color: var(--white);
  white-space: nowrap;
  pointer-events: none;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px 8px;
  font-size: 12px;
  animation: .3s backwards ov64_a_popover-intro;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes ov64_a_popover-intro {
  0% {
    opacity: 0;
    transform: translateX(-50%)translateY(-10px);
  }
}

.ov64_a_Button {
  border-radius: var(--radius);
  font-weight: var(--semibold);
  letter-spacing: .02em;
  text-align: center;
  white-space: nowrap;
  vertical-align: bottom;
  padding: 10px 20px;
  font-size: 13px;
  line-height: 15px;
  transition: box-shadow 75ms, border-color 75ms, opacity 75ms;
  box-shadow: 0 0 3px #9c969605, 0 0 12px #9c96961a;
}

.ov64_a_Button--round {
  --radius: 100px;
  padding: 10px 20px;
}

.ov64_a_Button--square {
  --radius: 4px;
  padding: 8px 17px;
}

.ov64_a_Button--size-large {
  padding: 20px 40px;
  font-size: 18px;
}

.ov64_a_Button--size-slim {
  padding: 6px 16px;
}

.ov64_a_Button--has-icon {
  padding-left: 15px;
}

.ov64_a_Button--has-icon:before {
  content: "";
  width: 16px;
  height: 16px;
  vertical-align: bottom;
  margin-right: 8px;
  display: inline-block;
}

.ov64_a_Button:hover {
  box-shadow: 0 0 3px #9c96961a, 0 0 12px #9c969633;
}

.ov64_a_Button--primary {
  background-color: var(--green);
  color: var(--white);
}

.ov64_a_Button--primary:hover {
  background-color: var(--green-dark);
}

.ov64_a_Button--secondary {
  background-color: var(--gray2);
  color: var(--black);
  box-shadow: none;
}

.ov64_a_Button--secondary:hover {
  box-shadow: 0 0 12px #9c96961a;
}

.ov64_a_Button--neutral {
  background-color: var(--blue);
  color: var(--white);
}

.ov64_a_Button--gray {
  background-color: var(--secondary-gray);
  color: #000;
}

.ov64_a_Button--light-red {
  background-color: var(--red-light);
  color: #000;
}

.ov64_a_Button--light-blue {
  background-color: var(--blue-light);
  color: #000;
}

.ov64_a_Button--light-yellow {
  background-color: var(--yellow-light);
  color: #000;
}

.ov64_a_Button--light-green {
  background-color: var(--green-light);
  color: #000;
}

.ov64_a_Button--red {
  background-color: var(--red);
  color: #fff;
}

.ov64_a_Button--blue {
  background-color: var(--blue);
  color: #fff;
}

.ov64_a_Button--yellow {
  background-color: var(--yellow);
  color: #000;
}

.ov64_a_Button--green {
  background-color: var(--green);
  color: #fff;
}

.ov64_a_Button--danger {
  background-color: var(--red);
  color: #fff;
}

.ov64_a_Button--danger:hover {
  background-color: var(--red-dark);
}

.ov64_a_Button--bare {
  color: inherit;
  box-shadow: none;
  opacity: .8;
  background-color: #0000;
  padding-left: 10px;
  padding-right: 10px;
}

.ov64_a_Button--bare:hover {
  box-shadow: none;
  opacity: 1;
}

.ov64_a_ButtonBar {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.ov64_a_ButtonBar--no-spread {
  flex: initial;
}

.ov64_a_ButtonBar--default {
  column-gap: 10px;
}

.ov64_a_ButtonBar--lozenge .ov64_a_Button {
  border-radius: 0;
  margin: 0;
}

.ov64_a_ButtonBar--lozenge .ov64_a_Button--round:not(:first-child) {
  padding-left: 15px;
}

.ov64_a_ButtonBar--lozenge .ov64_a_Button--round:not(:last-child) {
  padding-right: 15px;
}

.ov64_a_ButtonBar--lozenge .ov64_a_Button:first-child {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
}

.ov64_a_ButtonBar--lozenge .ov64_a_Button:last-child {
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

.ov64_a_ButtonBar--center {
  flex: 1;
  justify-content: center;
  column-gap: 10px;
}

.ov64_a_ButtonList h2 {
  margin-bottom: .5em;
}

.ov64_a_ButtonList + .ov64_a_ButtonList {
  margin-top: 20px;
}

.ov64_a_ButtonList .ov64_a_Button {
  display: block;
}

.ov64_a_ButtonList .ov64_a_Button + .ov64_a_Button {
  margin: 10px 0 0;
}

.ov64_a_Link {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
}

.ov64_a_Link--larger-hit-area {
  margin: -.5em;
  padding: .5em;
}

.ov64_a_Link--default {
  color: inherit;
}

.ov64_a_Link--default:hover {
  text-decoration: underline;
}

.ov64_a_Link--prominent {
  color: var(--link-color);
  text-decoration: underline;
}

.ov64_a_Link--subtle {
  color: inherit;
}

.ov64_a_Link--subtle:hover {
  text-decoration: underline;
}

.ov64_a_Link--danger {
  color: var(--red-dark);
  text-decoration: underline;
}

.ov64_a_Link--danger:hover {
  color: var(--red-darker);
}

.ov64_a_Link--external:after {
  content: "";
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background-image: url("external-link.1c99e7ea.svg");
  background-repeat: no-repeat;
  background-size: .8em;
  margin-left: .5em;
  display: inline-block;
}

.ov64_a_Link--external:hover {
  text-decoration: underline;
}

.ov64_a_Button + .ov64_a_Link {
  margin-left: calc(20px - .5em);
}

.ov64_a_ButtonBar .ov64_a_Link {
  font-size: 14px;
}

.ov64_a_SharpButton {
  min-height: 35px;
  font-weight: var(--semibold);
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 13px;
  line-height: 15px;
}

.ov64_a_SharpButton--default {
  background-color: var(--blue);
  color: var(--white);
}

.ov64_a_SharpButton:after {
  content: "";
  width: 12px;
  height: 15px;
  vertical-align: bottom;
  background-image: url("wings-right.09155ca9.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
  margin-left: 12px;
  display: inline-block;
}

.ov64_a_HelpButton {
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  background: var(--gray1);
  border: 1px solid var(--gray2);
  background-image: url("help.778d1081.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 50%;
  align-self: center;
  box-shadow: 0 0 1px #0000001a;
}

.ov64_a_SmallButton {
  font-size: 13px;
  line-height: 14px;
  font-weight: var(--semibold);
  white-space: nowrap;
  border-radius: 5px;
  padding: 5px 12px;
  transition: background-color 75ms;
}

.ov64_a_SmallButton--default {
  background-color: var(--gray1);
  color: var(--black);
  box-shadow: var(--shadow-clickable);
}

.ov64_a_SmallButton--default:hover, .ov64_a_SmallButton--back:hover {
  background-color: var(--gray2);
  color: var(--black);
}

.ov64_a_SmallButton--back {
  color: inherit;
  background-color: #0000;
  margin-left: -10px;
  padding-left: 10px;
}

.ov64_a_SmallButton--back:before {
  content: "";
  width: 10px;
  height: 11px;
  vertical-align: baseline;
  background-image: url("chevron-left-black.0d881c9a.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 6px;
  margin-right: 6px;
  display: inline-block;
}

.ov64_a_SlimIconButton {
  min-height: 30px;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 20px;
  font-weight: var(--semibold);
  box-shadow: var(--shadow-clickable);
  white-space: nowrap;
  border-radius: 4px;
  align-items: center;
  column-gap: 10px;
  padding: 5px 10px;
  transition: background-color 75ms;
  display: inline-flex;
}

.ov64_a_SlimIconButton--default {
  background-color: var(--secondary-gray);
  color: var(--black);
}

.ov64_a_SlimIconButton--default:hover {
  background-color: var(--gray2);
  color: var(--black);
}

.ov64_a_SlimIconButton--yellow {
  background-color: var(--yellow);
  color: var(--black);
}

.ov64_a_SlimIconButton--yellow:hover {
  background-color: var(--yellow-dark);
  color: var(--black);
}

.ov64_a_SlimIconButton--blue {
  background-color: var(--blue);
  color: var(--white);
}

.ov64_a_SlimIconButton--blue:hover {
  background-color: var(--blue-darker);
  color: var(--white);
}

.ov64_a_SlimIconButton--no-text {
  column-gap: 0;
}

.ov64_a_SlimIconButton--is-selected {
  box-shadow: inset 0 2px 3px #0000001a, inset 0 0 3px 1px #0000000d;
}

.ov64_a_SlimIconButton:focus-visible {
  outline: 1px solid gray;
}

.ov64_a_SlimIconButton:before {
  content: "";
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  display: inline-block;
}

.ov64_a_Icon--edit:before {
  background-image: url("edit.18f9edcd.svg");
}

.ov64_a_Icon--pick:before {
  background-image: url("pick.c94789a8.svg");
}

.ov64_a_Icon--trash:before {
  background-image: url("trash.89e850a0.svg");
}

.ov64_a_Icon--cancel:before {
  background-image: url("cancel.78dade79.svg");
}

.ov64_a_Icon--search:before {
  background-image: url("search.0656e808.svg");
}

.ov64_a_Icon--refresh:before {
  background-image: url("refresh.567d1083.svg");
}

.ov64_a_Icon--stats:before {
  background-image: url("stats.3d6ace8b.svg");
}

.ov64_a_Icon--activity:before {
  background-image: url("activity.69881997.svg");
}

.ov64_a_Icon--down:before {
  background-image: url("chevron-down.52252869.svg");
}

.ov64_a_Icon--up:before {
  background-image: url("chevron-down.52252869.svg");
  transform: scaleY(-1);
}

.ov64_a_Icon--add:before {
  background-image: url("plus.f987e3e2.svg");
}

.ov64_a_Icon--move:before {
  background-image: url("move.3639b196.svg");
}

.ov64_a_Icon--filter:before {
  background-image: url("filter.c1b372a4.svg");
}

.ov64_a_Icon--filter.ov64_a_Icon--is-selected:before {
  background-image: url("filter-selected.741248d5.svg");
}

._6ifHFG_NotifyBanner {
  bottom: 0;
  left: var(--menu-width);
  width: calc(100% - var(--menu-width));
  z-index: var(--index-notification);
  background: #fff;
  border-top: 1px solid #0000001a;
  position: fixed;
  box-shadow: 0 10px 25px #0003;
}

._6ifHFG_messages {
  list-style: none;
}

._6ifHFG_item {
  min-height: 60px;
  border-bottom: 1px solid #0000001a;
  align-items: center;
  padding: 10px 100px;
  display: flex;
}

._6ifHFG_item:last-child {
  border-bottom: none;
}

._6ifHFG_isError {
  color: red;
}

._6ifHFG_text {
  font-weight: 600;
}

._6ifHFG_button {
  margin-left: auto;
}

._6ifHFG_text:before {
  content: "";
  width: 1.2em;
  height: 1.2em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 .5em -.2em 0;
  display: inline-block;
}

._6ifHFG_isError ._6ifHFG_text:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='red' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-triangle'%3E%3Cpath d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'/%3E%3Cline x1='12' y1='9' x2='12' y2='13'/%3E%3Cline x1='12' y1='17' x2='12.01' y2='17'/%3E%3C/svg%3E");
}

._6ifHFG_isInfo ._6ifHFG_text:before {
  background-image: url("info.0c73e176.svg");
}

._6ifHFG_close {
  width: 40px;
  height: 40px;
  opacity: .6;
  cursor: pointer;
  background-color: #0000;
  background-image: url("x.ff5d9f4b.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border: none;
  position: absolute;
  left: 30px;
}

._6ifHFG_close:hover {
  opacity: 1;
}

.ha4ewW_Menu {
  --x-space: 12px;
  background: var(--menu-background);
  z-index: var(--index-menu);
  border-top: none;
  border-bottom: none;
  border-right: 2px solid #00000014;
  flex-direction: column;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 15px #8778780d;
}

.ha4ewW_item {
  padding: 8px var(--x-space);
  color: inherit;
  font-size: 16px;
  font-weight: var(--bold);
  border-radius: 5px;
  outline: none;
  margin: 4px 6px;
  line-height: 20px;
  text-decoration: none;
  display: block;
  position: relative;
}

.ha4ewW_item:hover {
  background-color: var(--gray2);
}

.ha4ewW_active, .ha4ewW_active:hover {
  background: var(--purple);
  color: #fff;
  box-shadow: 0 0 3px #837c7c05, 0 0 15px #837c7c1a, 15px 0 8px -5px #837c7c0d;
}

.ha4ewW_item::selection {
  background: none;
}

.ha4ewW_item--external:after {
  content: "";
  width: 14px;
  height: 14px;
  vertical-align: middle;
  background-image: url("external-link.1c99e7ea.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  margin: -3px 0 0 10px;
  display: inline-block;
}

.ha4ewW_logo {
  height: 25px;
  background-image: url("beagle.2026fea6.svg");
  background-size: auto 25px;
  background-position: var(--x-space) top;
  background-repeat: no-repeat;
  margin: 20px 0 0;
  display: block;
}

@media (height >= 900px) {
  .ha4ewW_logo {
    height: 30px;
    background-size: auto 30px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
}

.ha4ewW_MenuSection__title::selection {
  background: none;
}

.ha4ewW_MenuSection__title {
  font-size: 14px;
  font-weight: var(--semibold);
  padding: 10px var(--x-space);
  cursor: pointer;
  margin: 10px 0 0;
}

.ha4ewW_MenuSection__title:after {
  content: " +";
  float: right;
  vertical-align: bottom;
  opacity: .6;
  font-size: 1.2em;
  line-height: .9em;
  display: inline-block;
}

.ha4ewW_MenuSection__title:hover:after {
  opacity: 1;
}

.ha4ewW_MenuSection__title--isOpen:after {
  content: " -";
  font-size: 1.4em;
}

.ha4ewW_footer {
  padding: 10px var(--x-space);
  margin-top: auto;
  font-size: 12px;
}

.ha4ewW_footer__link {
  cursor: pointer;
}

.ha4ewW_footer__link:hover {
  text-decoration: underline;
}

.o3k4OG_ErrorBoundary {
  max-width: 90%;
  padding-left: var(--menu-width);
  margin: 50px;
}

.o3k4OG_ErrorBoundary__prompt {
  margin: 20px 0;
}

.o3k4OG_ErrorBoundary__error {
  background: var(--white);
  color: var(--red);
  tab-size: 4;
  word-break: break-all;
  border-radius: 5px;
  padding: 20px;
  font-size: 18px;
}

.o3k4OG_ErrorBoundary__errorPart {
  white-space: pre-wrap;
  margin-bottom: 10px;
}

.dDTktW_Header {
  box-sizing: border-box;
  width: 100%;
  align-items: middle;
  padding: 10px var(--pad-x) 10px;
  grid-gap: 5px 20px;
  grid-template: "dDTktW_back dDTktW_extra"
                 "dDTktW_title dDTktW_actions"
                 "dDTktW_subtitle dDTktW_aux"
                 / auto 1fr;
  margin: 0 0 20px;
  display: grid;
}

.dDTktW_Header--show-lead {
  grid-template-rows: auto auto auto;
  grid-template-areas: "dDTktW_back dDTktW_extra"
                       "dDTktW_title dDTktW_actions"
                       "dDTktW_lead dDTktW_lead";
}

.dDTktW_Header--is-sticky {
  z-index: var(--index-header);
  background-color: var(--background-color);
  padding-bottom: 20px;
  position: sticky;
  top: 0;
}

.dDTktW_Header--is-stuck {
  box-shadow: 0 12px 14px -14px #0000001a;
}

.dDTktW_Header--is-short {
  padding: 30px var(--pad-x) 20px;
}

.dDTktW_topRow {
  grid-area: dDTktW_back;
}

.dDTktW_extra {
  grid-area: dDTktW_extra;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.dDTktW_titleBar {
  grid-gap: 20px;
  grid-area: dDTktW_title;
  align-self: center;
  display: flex;
}

.dDTktW_title {
  font-size: 30px;
  line-height: 1em;
  font-weight: var(--bold);
}

.dDTktW_subtitle {
  vertical-align: baseline;
  font-size: 14px;
  line-height: 1em;
  font-weight: var(--regular);
  grid-area: dDTktW_subtitle;
  margin-top: 5px;
}

.dDTktW_Header--is-stuck .dDTktW_title, .dDTktW_Header--is-short .dDTktW_title {
  font-size: 30px;
  line-height: 35px;
}

.dDTktW_lead {
  width: 100%;
  grid-area: dDTktW_lead;
}

.dDTktW_actions {
  grid-area: dDTktW_actions;
}

.eVZd5q_Loader {
  padding: 20px var(--pad-x);
  font-size: 30px;
  font-weight: var(--bold);
  color: var(--gray4);
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.eVZd5q_Error__message {
  color: var(--red-dark);
}

.diOI5q_Page {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.diOI5q_Page + .diOI5q_Page {
  --top-space: 0px;
}

.diOI5q_content {
  padding: 0 var(--pad-x) 100px;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.diOI5q_content--width-wide {
  padding-left: 0;
  padding-right: 0;
}

.diOI5q_content--width-medium {
  max-width: var(--content-width-medium);
}

.diOI5q_content--width-large {
  max-width: var(--content-width-large);
}

.CWA-nG_Dashboard {
  grid-gap: 25px;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
}

.CWA-nG_DashboardItem {
  width: 270px;
  box-sizing: border-box;
  aspect-ratio: 1 / 1;
  background: var(--purple);
  color: var(--white);
  border-radius: 10px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 30px;
  display: flex;
  box-shadow: 0 1px 2px #0a00001a, 0 1px 15px #0a00001a;
}

.CWA-nG_DashboardItem:hover {
  text-decoration: none;
  box-shadow: 0 1px 2px #0a000026, 0 1px 25px #0a00001a;
}

.CWA-nG_DashboardItem--purple {
  background: var(--purple);
  color: var(--white);
}

.CWA-nG_DashboardItem--red {
  background: var(--red);
  color: var(--black);
  text-shadow: 0 .5px 1px #fff6;
}

.CWA-nG_DashboardItem--yellow {
  background: var(--yellow);
  color: var(--black);
  text-shadow: 0 .5px 1px #0006;
}

.CWA-nG_DashboardItem--green {
  background: var(--green);
  color: var(--white);
}

.CWA-nG_DashboardItem__category {
  font-size: 16px;
  font-weight: var(--regular);
  margin-bottom: 0;
}

.CWA-nG_DashboardItem__title {
  text-align: center;
  min-height: 40px;
  font-size: 20px;
  line-height: 1;
  font-weight: var(--semibold);
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.CWA-nG_DashboardItem__value {
  text-align: center;
  white-space: nowrap;
  font-size: 60px;
  line-height: 1;
  font-weight: var(--bold);
  margin-bottom: auto;
}

.CWA-nG_DashboardItem__sub {
  font-size: 16px;
  font-weight: var(--bold);
  text-align: center;
  margin-top: 10px;
}

.n2DoDq_ExternalLink {
  cursor: pointer;
  text-decoration: underline;
}

.n2DoDq_ExternalLink:after {
  content: "";
  width: .8em;
  height: .8em;
  vertical-align: middle;
  background-image: url("external-link.1c99e7ea.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -.2em;
  margin-left: .25em;
  margin-right: .25em;
  display: inline-block;
}

.n2DoDq_OverflowFade {
  max-height: 1.3em;
  -webkit-user-select: all;
  user-select: all;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.n2DoDq_OverflowFade:after {
  content: "";
  height: 100%;
  width: 2em;
  background: linear-gradient(90deg, transparent, var(--background-color));
  position: absolute;
  top: 0;
  right: 0;
}

.n2DoDq_SectionTitle {
  margin-top: var(--section-spacing);
  vertical-align: middle;
  margin-bottom: 20px;
  font-size: 26px;
}

.n2DoDq_SectionTitle:first-child {
  margin-top: 0;
}

.n2DoDq_SubSectionTitle {
  vertical-align: middle;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 22px;
}

.n2DoDq_SubSectionTitle:first-child {
  margin-top: 0;
}

.n2DoDq_Label {
  white-space: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
}

.n2DoDq_Label__subLabel {
  margin-left: .5em;
  font-weight: 500;
}

.n2DoDq_Red {
  color: var(--red-dark);
}

.n2DoDq_Red--bold {
  font-weight: var(--bold);
}

.n2DoDq_Line {
  line-height: 35px;
  display: flex;
}

.n2DoDq_Line--large {
  font-size: 30px;
}

.n2DoDq_Line--medium {
  font-size: 26px;
}

.n2DoDq_Line--fade {
  color: var(--gray3);
}

.n2DoDq_Line > input {
  height: 35px;
}

.n2DoDq_LabelledText {
  text-align: center;
  flex-direction: column;
  row-gap: 10px;
  display: flex;
}

.n2DoDq_LabelledText--tight {
  row-gap: 0;
}

.n2DoDq_LabelledText__label {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
}

.n2DoDq_LabelledText p {
  font-size: var(--editor-normal-text);
}

.n2DoDq_LabelledBlock {
  flex-direction: column;
  row-gap: 10px;
  display: flex;
}

.n2DoDq_LabelledBlock__label {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
}

.n2DoDq_LabelledBlock p {
  font-size: var(--editor-normal-text);
}

.n2DoDq_SmallText {
  font-size: 14px;
  font-weight: var(--semibold);
}

.HMHcVW_Field {
  --initial-shadow: 0 0 3px #9c969605, 0 0 12px #9c96960d;
  --hover-shadow: 0 0 3px #9c96961a, 0 0 16px #9c96961a;
  --field-min-height: 32px;
  --focus-color: var(--green-light);
  padding: var(--field-padding-y, 10px) var(--field-padding-x, 10px);
  cursor: text;
  background: var(--field-background, var(--white));
  box-shadow: var(--field-shadow, var(--initial-shadow));
  border: 1px solid #0000;
  border-radius: 4px;
  outline: none;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.4;
  transition: box-shadow 75ms, border-color 75ms;
  display: flex;
  position: relative;
}

.HMHcVW_Field:hover {
  box-shadow: var(--field-shadow, var(--hover-shadow));
}

.HMHcVW_Field--has-focus, .HMHcVW_Field--enable-focus-style:focus, .HMHcVW_Field--enable-focus-style:focus-within {
  border-color: var(--field-focus-color, var(--focus-color));
  box-shadow: var(--field-shadow, var(--hover-shadow));
  outline: none;
}

.HMHcVW_Field--is-disabled {
  background: var(--gray1);
  cursor: default;
}

.HMHcVW_Field--is-disabled:hover, .HMHcVW_Field--is-disabled:focus, .HMHcVW_Field--is-disabled.HMHcVW_Field--has-focus {
  box-shadow: var(--field-shadow, var(--initial-shadow));
}

.HMHcVW_Field--is-inline {
  box-shadow: none;
  background: none;
  border: none;
  flex-direction: column;
  padding: 0;
}

.HMHcVW_Field--is-inline.HMHcVW_Field--has-focus, .HMHcVW_Field--is-inline:focus, .HMHcVW_Field--is-inline:focus-within, .HMHcVW_Field--is-inline:hover {
  box-shadow: none;
}

.HMHcVW_Field--is-simple {
  flex-direction: column;
  align-items: stretch;
}

.HMHcVW_Field--is-simple .HMHcVW_Input {
  padding-left: 0;
}

.HMHcVW_Field--is-invalid, .HMHcVW_Field--is-invalid:focus, .HMHcVW_Field--is-invalid:focus-within {
  outline: 1px solid red;
}

.HMHcVW_Field__labels {
  grid-gap: .5em;
  order: -2;
  align-items: baseline;
  display: flex;
}

.HMHcVW_Field__input {
  flex-flow: wrap;
  flex: 2;
  justify-content: flex-start;
  align-self: stretch;
  align-items: baseline;
  display: flex;
  position: relative;
}

.HMHcVW_Field__input[data-prefix]:before {
  content: attr(data-prefix);
  opacity: .7;
  order: -1;
  margin-right: 6px;
  display: inline-block;
}

.HMHcVW_Field__input > * {
  flex: 1;
}

.HMHcVW_Field__label, .HMHcVW_InputRow__label {
  color: var(--gray5);
  -webkit-user-select: none;
  user-select: none;
}

.HMHcVW_Field:focus-within .HMHcVW_Field__label, .HMHcVW_Field:focus-within .HMHcVW_InputRow__labelx {
  color: var(--gray6);
}

.HMHcVW_Description {
  color: var(--gray5);
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.HMHcVW_Input {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  box-shadow: none;
  min-height: var(--field-min-height);
  background: none;
  border: none;
  outline: none;
  flex: 1;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.HMHcVW_Input::placeholder {
  color: var(--gray3);
}

.HMHcVW_InputRow {
  flex: 100%;
  display: flex;
}

.HMHcVW_InputRow__label {
  align-items: center;
  margin-right: .5em;
}

.HMHcVW_Divider {
  font-weight: var(--bold);
  margin: 30px 0 10px;
}

.Uhz91a_LoadingSpinner {
  width: 40px;
  height: 40px;
  flex: 1;
  justify-content: center;
  align-items: center;
  animation: .5s .5s backwards Uhz91a_loading-intro;
  display: flex;
}

.Uhz91a_LoadingSpinner__inner {
  width: 100%;
  height: 100%;
  background-image: url("loader.625b5621.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  animation: 2s linear infinite Uhz91a_loading-spinner;
  display: block;
}

@keyframes Uhz91a_loading-intro {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes Uhz91a_loading-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tQAtLG_Form, .tQAtLG_fade {
  grid-gap: 20px;
  grid-template-columns: 1fr;
  display: grid;
  position: relative;
}

.tQAtLG_content {
  transition: opacity .3s;
}

.tQAtLG_content--isLoading .tQAtLG_fade {
  opacity: .3;
  pointer-events: none;
  transition-duration: .15s;
}

.tQAtLG_loader {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 35%;
  left: 50%;
}

.dyZVQG_Bubble {
  background: var(--yellow);
  z-index: 1;
  opacity: 0;
  border-radius: 3px;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 500;
  transition: opacity .3s, transform .3s;
  position: absolute;
  transform: translateY(5px);
  box-shadow: 1px 3px 9px #0000000d;
}

.dyZVQG_Bubble--is-visible {
  opacity: 1;
}

@keyframes dyZVQG_bubble-intro {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }

  5% {
    opacity: 0;
  }
}

@keyframes dyZVQG_bubble-outro {
  95% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateY(-5px);
  }
}

._74OK2q_Tags {
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  margin-top: 5px;
  display: flex;
}

._74OK2q_Tags__input, ._74OK2q_Tag {
  margin: 0 5px 5px 0;
  padding: 3px 3px 3px 8px;
  font-size: 14px;
  display: inline-block;
}

._74OK2q_Tag {
  background: var(--gray1);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 25px;
}

._74OK2q_Tag:hover {
  background: var(--gray2);
}

._74OK2q_Tag--is-selected, ._74OK2q_Tag--is-selected:hover {
  background: var(--green-lighter);
}

._74OK2q_Tag:after {
  content: "";
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background-image: url("plus.f987e3e2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -1px;
  margin-left: .5em;
  margin-right: .25em;
  display: inline-block;
}

._74OK2q_Tag--is-selected:after {
  background-image: url("check.8bdb7ed6.svg");
  animation: .75s steps(2, end) backwards _74OK2q_force-checkmark;
}

._74OK2q_Tag--is-selected:hover:after {
  background-image: url("minus.2bc6816f.svg");
}

._74OK2q_Tag__checkbox {
  display: none;
}

@keyframes _74OK2q_force-checkmark {
  0%, 99% {
    background-image: url("check.8bdb7ed6.svg");
  }
}

._74OK2q_Tags__input {
  min-height: 0;
  min-width: 40px;
}

.vJ8EuG_SelectField {
  cursor: pointer;
}

.vJ8EuG_SelectInput {
  height: 42px;
  border: 1px solid #0000;
  border-bottom-style: none;
  border-radius: 3px 3px 0 0;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding-left: 6px;
  display: flex;
  position: relative;
}

.vJ8EuG_SelectInput--is-open {
  border-color: var(--green-light);
  box-shadow: 0 0 5px #00000026;
}

.vJ8EuG_SelectInput--is-open .vJ8EuG_SelectInput__input::placeholder, .vJ8EuG_SelectInput--has-selection .vJ8EuG_SelectInput__input::placeholder {
  color: inherit;
}

.vJ8EuG_SelectInput__arrow {
  height: 100%;
  width: 50px;
  opacity: .3;
  background-image: url("chevron-down.52252869.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  transition: opacity .15s, transform .15s;
  position: absolute;
  top: 0;
  right: 0;
}

.vJ8EuG_SelectInput:hover .vJ8EuG_SelectInput__arrow {
  opacity: 1;
}

.vJ8EuG_SelectInput--is-open .vJ8EuG_SelectInput__arrow {
  transform: scale(1, -1);
}

.vJ8EuG_SelectInput__options {
  z-index: 10;
  width: 100%;
  border: 1px solid var(--green-light);
  cursor: default;
  background: #fff;
  border-top: none;
  border-radius: 0 0 3px 3px;
  margin-top: -5px;
  padding-top: 5px;
  animation: .15s backwards vJ8EuG_options-open;
  display: none;
  position: absolute;
  top: 100%;
  left: -1px;
  box-shadow: 0 5px 5px #00000026;
}

@keyframes vJ8EuG_options-open {
  0% {
    transform-origin: 0 0;
    transform: scaleY(0);
  }

  100% {
    transform-origin: 0 0;
    transform: scaleY(1);
  }
}

.vJ8EuG_SelectInput__options--is-open {
  display: block;
}

.vJ8EuG_SelectInput__option {
  cursor: pointer;
  padding: 5px 10px;
}

.vJ8EuG_SelectInput__option:hover {
  background: var(--green-lightest);
}

.vJ8EuG_SelectInput__option--is-selected, .vJ8EuG_SelectInput__option--is-selected:hover {
  background: var(--green-lighter);
}

.vJ8EuG_SelectInput__group::selection, .vJ8EuG_SelectInput__option::selection {
  background: none;
}

.vJ8EuG_SelectInput__group {
  opacity: .4;
}

.vJ8EuG_SelectInput__option--create-new {
  color: var(--link-color);
  text-decoration: underline;
}

.vJ8EuG_SelectInput__option[data-prefix]:before {
  content: attr(data-prefix);
  line-height: inherit;
  vertical-align: inherit;
  opacity: .7;
  margin-right: .25em;
  display: inline-block;
}

.U4qhya_ToggleSwitch {
  cursor: pointer;
}

.U4qhya_ToggleSwitch__output {
  align-items: center;
  display: flex;
}

.U4qhya_ToggleSwitch__toggle {
  width: 70px;
  height: 36px;
  cursor: pointer;
  border: 1px solid #00000026;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
  position: relative;
}

.U4qhya_ToggleSwitch__toggle--redgreen {
  background: var(--red);
}

.U4qhya_ToggleSwitch__checkbox:checked + .U4qhya_ToggleSwitch__toggle--redgreen {
  background: var(--green);
}

.U4qhya_ToggleSwitch__toggle--grayyellow {
  background: var(--gray1);
}

.U4qhya_ToggleSwitch__checkbox:checked + .U4qhya_ToggleSwitch__toggle--grayyellow {
  background: var(--yellow);
}

.U4qhya_ToggleSwitch__checkbox {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.U4qhya_ToggleSwitch__toggle:before {
  content: "";
  width: 26px;
  height: 26px;
  background: #fff;
  border: 1px solid #0003;
  border-radius: 14px;
  transition: transform .15s ease-in-out, color .15s linear;
  display: block;
  transform: translateX(-15px);
  box-shadow: 1px 2px 3px #0000001a;
}

.U4qhya_ToggleSwitch__checkbox:checked + .U4qhya_ToggleSwitch__toggle:before {
  transform: translateX(15px);
}

.U4qhya_ToggleSwitch__legend {
  opacity: .8;
  -webkit-user-select: none;
  user-select: none;
  font-size: 13px;
  display: inline-block;
}

.U4qhya_ToggleSwitch__off {
  display: block;
}

.U4qhya_ToggleSwitch__on, .U4qhya_ToggleSwitch__checkbox:checked ~ .U4qhya_ToggleSwitch__legend .U4qhya_ToggleSwitch__off {
  display: none;
}

.U4qhya_ToggleSwitch__checkbox:checked ~ .U4qhya_ToggleSwitch__legend .U4qhya_ToggleSwitch__on {
  display: block;
}

.U4qhya_ToggleSwitch__checkbox:focus + .U4qhya_ToggleSwitch__toggle {
  box-shadow: 0 0 1px 2px var(--green-light), 0 0 8px 2px var(--green-light);
}

.RoytTW_expander, .RoytTW_input {
  --line-height: 1.6em;
  font-size: inherit;
  line-height: inherit;
  resize: vertical;
  font-family: inherit;
  font-size: inherit;
  line-height: var(--line-height);
  box-sizing: border-box;
  white-space: pre-line;
  max-height: 50vh;
  tab-size: 4;
  background: none;
  border: none;
  flex: 1;
  padding: 0;
}

.RoytTW_input:focus {
  outline: none;
}

.RoytTW_expander {
  pointer-events: none;
  visibility: hidden;
  padding-bottom: var(--line-height);
  min-height: calc(var(--line-height) * 4);
}

.RoytTW_input {
  width: 100%;
  height: 100%;
  resize: none;
  position: absolute;
  top: 0;
  left: 0;
}

.RoytTW_size--small {
  font-size: 15px;
}

.RoytTW_size--large {
  font-size: 20px;
}

.plNkgW_expander, .plNkgW_input {
  --line-height: 1.6em;
  font-size: inherit;
  line-height: inherit;
  resize: vertical;
  font-family: inherit;
  font-size: inherit;
  line-height: var(--line-height);
  box-sizing: border-box;
  white-space: pre-line;
  max-height: 50vh;
  tab-size: 4;
  background: none;
  border: none;
  flex: 1;
  padding: 0;
  font-family: monospace;
}

.plNkgW_input:focus {
  outline: none;
}

.plNkgW_expander {
  pointer-events: none;
  visibility: hidden;
  padding-bottom: var(--line-height);
  min-height: calc(var(--line-height) * 4);
}

.plNkgW_input {
  width: 100%;
  height: 100%;
  resize: none;
  position: absolute;
  top: 0;
  left: 0;
}

.N7cVRW_TransparentInput {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  box-shadow: none;
  background: none;
  border: none;
  border-radius: 4px;
  outline: none;
  flex: 1;
  margin: -5px;
  padding: 5px;
  display: inline-block;
}

.N7cVRW_TransparentInput--has-focus-style:focus {
  background: var(--off-white);
}

.N7cVRW_TransparentInput::placeholder {
  color: var(--gray3);
}

.N7cVRW_TransparentSelect {
  cursor: pointer;
  align-items: center;
  display: flex;
  position: relative;
}

.N7cVRW_TransparentSelect__input {
  display: none;
}

.N7cVRW_TransparentSelect__placeholder {
  color: var(--gray3);
}

.N7cVRW_TransparentSelect__options {
  z-index: var(--index-dropdown);
  background: var(--white);
  box-shadow: var(--card-shadow), var(--wide-shadow), var(--tight-shadow);
  border: 1px solid var(--secondary-gray);
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  top: 0;
  transform: translateY(-30%);
}

.N7cVRW_TransparentSelect__blankOption, .N7cVRW_TransparentSelect__option {
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  padding: 0 10px;
  line-height: 1.8em;
  display: flex;
}

.N7cVRW_TransparentSelect__blankOption:hover, .N7cVRW_TransparentSelect__option:hover {
  background: var(--secondary-gray);
}

.N7cVRW_TransparentSelect__blankOption:before {
  content: "";
  width: 1em;
  height: 1em;
  background-image: url("cancel.78dade79.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: .8em;
  margin-right: .2em;
  display: inline-block;
}

.zn8J6W_LoginPage {
  height: 100%;
  width: 100%;
  align-items: top;
  background: linear-gradient(-30deg, #defff7, #ffe6de);
  justify-content: center;
  display: flex;
}

.zn8J6W_content {
  min-width: 300px;
  width: 30vw;
  margin: 30vh auto auto;
  font-size: 18px;
  line-height: 1.5;
  position: relative;
}

.zn8J6W_form {
  transition: opacity .3s ease-in-out;
  animation: .3s ease-in-out backwards zn8J6W_fade-in;
}

@keyframes zn8J6W_fade-in {
  0% {
    opacity: 0;
  }
}

.zn8J6W_isLoading .zn8J6W_form {
  opacity: 0;
}

.zn8J6W_loading {
  opacity: 0;
  text-align: center;
  pointer-events: none;
  transition: opacity .3s ease-in-out;
}

.zn8J6W_isLoading .zn8J6W_loading {
  opacity: 1;
}

.zn8J6W_loading p {
  margin-top: 1em;
}

.zn8J6W_loader {
  pointer-events: none;
  width: 100px;
  height: 100px;
  background-image: url("loader.625b5621.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -50px;
  margin-left: -50px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.zn8J6W_isLoading .zn8J6W_loader {
  opacity: .5;
  transition: opacity .3s ease-in-out .1s;
  animation: 3s linear infinite zn8J6W_spinning-loader;
}

@keyframes zn8J6W_spinning-loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.vXVORq_PriceEditor {
  font-size: var(--editor-medium-text);
}

.vXVORq_PriceEditor__amount, .vXVORq_PriceEditor__currency {
  min-width: 1em;
  display: inline-block;
}

.K1qr8a_CategoryEditor {
  --spacer-gap: 10px;
  font-size: var(--editor-medium-text);
  flex-wrap: wrap;
  gap: 10px 20px;
  display: flex;
}

.K1qr8a_CategoryEditor:focus {
  background: var(--off-white);
  border-radius: 5px;
  outline: none;
  margin: -10px -5px;
  padding: 10px 5px;
}

.K1qr8a_CategoryEditor__input {
  display: none;
}

.K1qr8a_CategoryBrowser {
  flex-wrap: wrap;
  gap: 10px 40px;
  display: flex;
}

.K1qr8a_CategoryBrowser__path {
  column-gap: var(--spacer-gap);
  flex-wrap: wrap;
  display: flex;
}

.K1qr8a_CategoryBrowser__stem {
  transition: opacity .15s;
}

.K1qr8a_CategoryBrowser__stem--fade {
  opacity: .7;
}

.K1qr8a_CategoryBrowser__stem--fade:hover {
  opacity: 1;
}

.K1qr8a_CategoryBrowser__stem:after {
  content: "";
  margin-left: var(--spacer-gap);
  width: 16px;
  height: 16px;
  opacity: .8;
  background-image: url("chevron-right.2f969e10.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  display: inline-block;
}

.K1qr8a_CategorySearcher {
  min-width: 450px;
  border-radius: 5px;
  position: relative;
}

.K1qr8a_CategorySearcher:focus-within {
  background: var(--off-white);
  margin: -8px -10px;
  padding: 8px 10px;
}

.K1qr8a_CategorySearcher__inputs {
  display: flex;
}

.K1qr8a_CategorySearcher__cancel {
  width: auto;
}

.K1qr8a_CategorySearcher__input {
  flex: 1;
}

.K1qr8a_ResultsList {
  width: 100%;
  z-index: var(--index-dropdown);
  border: 1px solid var(--gray1);
  box-shadow: var(--wide-shadow);
  background: #fff;
  border-top: 0;
  padding: 5px 0;
  line-height: 1.2;
  list-style: none;
  animation: .15s K1qr8a_results-intro;
  position: absolute;
  top: 100%;
  left: 0;
}

.K1qr8a_ResultsList__result {
  cursor: pointer;
  min-height: 48px;
  grid-gap: 5px;
  border-bottom: 1px solid var(--gray1);
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  display: flex;
}

.K1qr8a_ResultsList__result--focus, .K1qr8a_ResultsList__result:hover {
  background: var(--blue-light);
}

.K1qr8a_ResultsList__resultPath {
  color: var(--gray4);
  font-size: 14px;
  display: block;
}

.K1qr8a_ResultsList__resultName {
  font-size: 20px;
}

@keyframes K1qr8a_results-intro {
  0% {
    opacity: 0;
  }
}

.o6wVyW_BrandEditor {
  font-size: 20px;
}

.ASCbMq_TextEditor {
  font-size: inherit;
  display: flex;
  position: relative;
}

.ASCbMq_TextEditor--size-large {
  --line-height: 1.4em;
  font-size: var(--editor-large-text);
}

.ASCbMq_TextEditor--size-normal {
  --line-height: 1.4em;
  font-size: var(--editor-normal-text);
}

.ASCbMq_TextEditor--size-small {
  --line-height: 1.4em;
  font-size: var(--editor-small-text);
}

.ASCbMq_TextEditor__fill, .ASCbMq_TextEditor__input {
  resize: none;
  font: inherit;
  line-height: var(--line-height);
  white-space: pre-line;
  word-break: break-word;
  border: none;
  margin: 0;
  padding: 0;
}

.ASCbMq_TextEditor__input {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ASCbMq_TextEditor__input:focus {
  outline: none;
}

.ASCbMq_TextEditor__fill {
  visibility: hidden;
  pointer-events: none;
  padding-bottom: var(--line-height);
}

.ZOsDFa_LineEditor {
  flex: 1;
  display: flex;
}

.ZOsDFa_LineEditor__input {
  flex: 1;
}

.ZOsDFa_large {
  font-size: var(--editor-large-text);
}

.ZOsDFa_medium {
  font-size: var(--editor-medium-text);
}

.ZOsDFa_normal {
  font-size: var(--editor-normal-text);
}

.ZOsDFa_small {
  font-size: var(--editor-small-text);
}

._6sCMBW_DateEditor {
  flex: 1;
}

._6sCMBW_DateEditor__clear {
  font-size: 12px;
  font-weight: var(--bold);
  cursor: pointer;
  background: none;
  border: none;
  margin: 0 0 0 20px;
  padding: 0;
}

._8sZSDW_ImageEditor {
  width: 230px;
  flex-direction: column;
  row-gap: 5px;
  display: flex;
}

._8sZSDW_ImageEditor__preview {
  width: 230px;
  height: 200px;
  background: var(--white);
  box-shadow: var(--wide-shadow), var(--tight-shadow);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

._8sZSDW_ImageEditor__preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

._8sZSDW_ImageEditor__size {
  background: #ffffffe6;
  border-radius: 4px 0 0;
  padding: 0 5px;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
}

._8sZSDW_ImageEditor__size--too-small {
  background: var(--red);
}

._8sZSDW_ImageEditor__input {
  text-overflow: ellipsis;
  overflow: hidden;
}

._4KX09q_ImagePreview {
  grid-gap: 0px 20px;
  grid-template: "_4KX09q_preview _4KX09q_meta"
                 "_4KX09q_actions _4KX09q_meta" 1fr
                 / 1fr 2fr;
  display: grid;
}

._4KX09q_ImagePreview__preview {
  grid-area: _4KX09q_preview;
  justify-content: stretch;
  align-items: stretch;
  display: flex;
}

._4KX09q_ImagePreview__image {
  flex: 1;
}

._4KX09q_ImagePreview__frame {
  background: var(--off-white);
  border: 1px solid var(--gray2);
  border-radius: 3px;
  padding: 5px;
}

._4KX09q_ImagePreview__preview img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

._4KX09q_ImagePreview__meta {
  --field-padding-x: 10px;
  --field-padding-y: 10px;
  --field-background: var(--off-white);
  grid-gap: 10px;
  flex-direction: column;
  grid-area: _4KX09q_meta;
  display: flex;
}

._4KX09q_ImagePreview__actions {
  grid-gap: 20px;
  flex-direction: column;
  grid-area: _4KX09q_actions;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

._4KX09q_ImageUploader {
  height: 150px;
  grid-gap: 20px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

._4KX09q_ImageUploader__cta {
  color: var(--blue-darker);
  font-size: 24px;
  font-weight: var(--regular);
  text-decoration: underline;
}

._4KX09q_ImageUploader__error, ._4KX09q_ImageUploader__status {
  color: var(--gray4);
  font-size: 24px;
  font-weight: var(--regular);
}

._4KX09q_ImageUploader__error {
  color: var(--red-dark);
}

._4KX09q_ImageUploader input[type="file"] {
  display: none;
}

.I_gUJG_MultiSelectEditor {
  grid-gap: 10px;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  display: flex;
}

.I_gUJG_MultiSelectEditor__option {
  font-size: 16px;
  font-weight: var(--semibold);
  height: 30px;
  box-sizing: border-box;
  border: 1px solid var(--gray1);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border-radius: 8px;
  padding: 5px 5px 5px 10px;
  line-height: 20px;
  display: inline-block;
}

.I_gUJG_MultiSelectEditor__option:hover {
  background: var(--gray2);
}

.I_gUJG_MultiSelectEditor__option--is-selected, .I_gUJG_MultiSelectEditor__option--is-selected:hover {
  background: var(--green-light);
  border-color: #0000;
}

.I_gUJG_MultiSelectEditor__option:after {
  content: "";
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background-image: url("plus.f987e3e2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -1px;
  margin-left: .5em;
  margin-right: .25em;
  display: inline-block;
}

.I_gUJG_MultiSelectEditor__option--is-selected:after {
  background-image: url("check.8bdb7ed6.svg");
  animation: .75s steps(2, end) backwards I_gUJG_force-checkmark;
}

.I_gUJG_MultiSelectEditor__option--is-selected:hover:after {
  background-image: url("minus.2bc6816f.svg");
}

.I_gUJG_MultiSelectEditor__optionCheckbox {
  display: none;
}

@keyframes I_gUJG_force-checkmark {
  0%, 99% {
    background-image: url("check.8bdb7ed6.svg");
  }
}

._4uvNYG_MultiInsertEditor {
  grid-gap: 10px;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  display: flex;
}

._4uvNYG_MultiInsertEditor__option {
  font-size: 16px;
  font-weight: var(--semibold);
  height: 30px;
  box-sizing: border-box;
  background: var(--green-light);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 8px;
  padding: 5px 5px 5px 10px;
  line-height: 20px;
  display: inline-block;
}

._4uvNYG_MultiInsertEditor__option:hover {
  background: var(--green-lighter);
}

._4uvNYG_MultiInsertEditor__option--is-selected, ._4uvNYG_MultiInsertEditor__option--is-selected:hover {
  background: var(--green-light);
}

._4uvNYG_MultiInsertEditor__option:after {
  content: "";
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background-image: url("check.8bdb7ed6.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -1px;
  margin-left: .5em;
  margin-right: .25em;
  display: inline-block;
}

._4uvNYG_MultiInsertEditor__option:hover:after {
  background-image: url("minus.2bc6816f.svg");
}

._4uvNYG_MultiInsertEditor__optionCheckbox {
  display: none;
}

.Dza3oa_BlocksEditor {
  grid-gap: 20px;
  --field-shadow: none;
  --field-focus-color: transparent;
  flex-direction: column;
  display: flex;
}

.Dza3oa_BlocksEditor__blocks {
  grid-gap: 20px;
  flex-direction: column;
  display: flex;
}

.Dza3oa_BlocksEditor__actions {
  grid-gap: 20px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Dza3oa_Block {
  grid-template: "Dza3oa_inner Dza3oa_actions" 1fr
  / 1fr auto;
  display: grid;
  position: relative;
}

.Dza3oa_Block__inner {
  background: var(--white);
  grid-gap: 20px;
  border-radius: 7px;
  grid-template: "Dza3oa_type Dza3oa_content" 1fr
  / 60px 1fr;
  grid-area: Dza3oa_inner;
  padding: 10px;
  display: grid;
}

.Dza3oa_Block__actions {
  grid-gap: 10px 0;
  opacity: 0;
  flex-direction: column;
  grid-area: Dza3oa_actions;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
  transition: opacity .15s;
  display: flex;
}

.Dza3oa_Block:hover .Dza3oa_Block__actions {
  opacity: 1;
}

.Dza3oa_Block__actionButton {
  background: var(--gray1);
}

.Dza3oa_Block__type {
  font-size: 14px;
  font-weight: var(--bold);
  grid-area: Dza3oa_type;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.Dza3oa_Block__typeBox {
  background-color: var(--secondary-gray);
  height: 55px;
  word-break: break-all;
  border-radius: 5px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.Dza3oa_Block__type img {
  display: block;
}

.Dza3oa_Block__variantButton, .Dza3oa_Block__variantOption {
  appearance: none;
  color: inherit;
  font: inherit;
  background-color: var(--secondary-gray);
  height: 55px;
  font-size: 20px;
  font-weight: var(--bold);
  box-shadow: none;
  border: 1px solid #0000;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Dza3oa_Block__variantButton:hover, .Dza3oa_Block__variantOption:hover {
  background-color: var(--gray2);
  box-shadow: none;
}

.Dza3oa_Block__variantButton:focus-visible, .Dza3oa_Block__variantOption:focus-visible {
  border-color: gray;
}

.Dza3oa_Block__variantButton {
  cursor: pointer;
  background-image: url("chevron-down.52252869.svg");
  background-position: 44px 2px;
  background-repeat: no-repeat;
  background-size: 14px;
  border-radius: 5px;
  flex: 1;
  align-self: stretch;
  padding: 0;
}

.Dza3oa_Block__variantSelector {
  width: 100%;
  background: var(--secondary-gray);
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.Dza3oa_Block__variantSelector--is-open {
  z-index: var(--index-block-variant-selector);
  box-shadow: 0 1px 4px #0000001a, 0 1px 14px #0000001a;
}

.Dza3oa_Block__content {
  grid-area: Dza3oa_content;
  display: grid;
}

.mN55rG_Tag {
  width: 80px;
  text-align: center;
  grid-gap: 5px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.mN55rG_Tag__img {
  width: 32px;
  height: 32px;
  display: block;
}

.mN55rG_Tag__label {
  font-size: 12px;
  font-weight: var(--bold);
  color: var(--purple);
  line-height: 14px;
}

.T5l0dq_Cert {
  width: 80px;
  text-align: center;
  grid-gap: 5px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.T5l0dq_Cert__img {
  width: 32px;
  height: 32px;
  display: block;
}

.T5l0dq_Cert__label {
  font-size: 12px;
  font-weight: var(--bold);
  color: var(--purple);
  line-height: 14px;
}

.aEOyZa_TagsAndCertsEditor {
  min-height: 150px;
  grid-gap: 20px;
  cursor: pointer;
  flex-direction: column;
  grid-template: "aEOyZa_tags"
                 "aEOyZa_certs"
                 "aEOyZa_actions"
                 / 1fr;
  justify-content: center;
  align-items: center;
  padding: 0 0 20px;
  display: grid;
}

.aEOyZa_TagsAndCertsEditor__title, .aEOyZa_TagsAndCertsEditor__missing {
  text-align: center;
  flex: 100%;
}

.aEOyZa_TagsAndCertsEditor__tags {
  background: var(--off-white);
  grid-gap: 15px 10px;
  border-radius: 5px;
  flex-wrap: wrap;
  grid-area: aEOyZa_tags;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  display: flex;
}

.aEOyZa_TagsAndCertsEditor__certifications {
  background: var(--off-white);
  grid-gap: 15px 10px;
  border-radius: 5px;
  flex-wrap: wrap;
  grid-area: aEOyZa_certs;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  display: flex;
}

.aEOyZa_TagsAndCertsEditor__cert {
  font-size: 12px;
  font-weight: var(--bold);
  color: var(--purple);
}

.aEOyZa_TagsAndCertsEditor__cta {
  color: var(--blue-darker);
  font-size: 20px;
  font-weight: var(--regular);
  grid-area: aEOyZa_actions;
  text-decoration: underline;
}

.otrcEG_QuoteEditor {
  --field-padding-y: 0;
  --field-padding-x: 0;
  grid-gap: 10px;
  flex-direction: column;
  display: flex;
}

.YEagka_VideoEmbedEditor {
  --field-padding-y: 0;
  --field-padding-x: 0;
  grid-gap: 10px;
  flex-direction: column;
  display: flex;
}

.rK5_aG_ProductListItem {
  grid-column-gap: 15px;
  min-height: 20px;
  background: var(--white);
  box-shadow: var(--wide-shadow);
  border-radius: 7px;
  grid-template-columns: auto 1fr auto auto;
  padding: 15px;
  font-size: 18px;
  line-height: 25px;
  display: grid;
}

.rK5_aG_ProductListItem + .rK5_aG_ProductListItem {
  margin-top: 10px;
}

.rK5_aG_ProductListItem__media {
  width: 65px;
  height: 65px;
  background: var(--off-white);
  text-align: left;
  border-radius: 3px;
  grid-column: 1;
  overflow: hidden;
  box-shadow: 0 1px 1px #0003;
}

.rK5_aG_ProductListItem__media img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.rK5_aG_ProductListItem__titles {
  grid-column: 2;
  align-self: center;
}

.rK5_aG_ProductListItem__title {
  font-weight: var(--bold);
  font-size: inherit;
  flex: 100%;
  margin: 5px 0 0;
  padding: 0;
  line-height: 20px;
}

.rK5_aG_ProductListItem__link {
  font: inherit;
  color: inherit;
}

.rK5_aG_ProductListItem__id {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--semibold);
  color: var(--gray4);
  margin-top: 5px;
  display: inline-block;
}

.rK5_aG_ProductListItem__meta {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--semibold);
  color: var(--gray4);
  margin-top: 5px;
}

.rK5_aG_ProductListItem__id {
  display: inline-block;
}

.rK5_aG_ProductListItem__rank {
  color: var(--black);
  font-weight: var(--bold);
  margin-left: 10px;
  display: inline-block;
}

.rK5_aG_ProductListItem__info {
  flex: 1;
  grid-column: 3;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.rK5_aG_ProductListItem__actions {
  flex: auto;
  grid-column: 4;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
  display: flex;
}

.rK5_aG_MinimalProductListItem {
  background: var(--white);
  background: #fdfdfd;
  border-radius: 3px;
  padding: 7px;
  display: block;
  box-shadow: 0 1px 3px #0000001a;
}

.rK5_aG_MinimalProductListItem + .rK5_aG_MinimalProductListItem {
  margin-top: 10px;
}

.rK5_aG_MinimalProductListItem__link {
  font: inherit;
  color: inherit;
  grid-column-gap: 10px;
  grid-template: "rK5_aG_media rK5_aG_title"
                 "rK5_aG_media rK5_aG_meta"
                 / auto 1fr;
  display: grid;
}

.rK5_aG_MinimalProductListItem__link:hover {
  text-decoration: none;
}

.rK5_aG_MinimalProductListItem__media {
  width: 40px;
  height: 40px;
  background: var(--off-white);
  text-align: left;
  border-radius: 3px;
  grid-area: rK5_aG_media;
  overflow: hidden;
}

.rK5_aG_MinimalProductListItem__media img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.rK5_aG_MinimalProductListItem__title {
  font-weight: var(--bold);
  font-size: inherit;
  flex: 100%;
  grid-area: rK5_aG_title;
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.rK5_aG_MinimalProductListItem__link:hover .rK5_aG_MinimalProductListItem__title {
  text-decoration: underline;
}

.rK5_aG_MinimalProductListItem__meta {
  font-size: 12px;
  line-height: 20px;
  font-weight: var(--semibold);
  color: var(--gray4);
  grid-area: rK5_aG_meta;
}

.Rrun5W_Categories {
  column-gap: var(--grid-column-gap);
  row-gap: var(--section-spacing);
  grid-template: "Rrun5W_edi Rrun5W_edi"
                 "Rrun5W_pro Rrun5W_pro"
                 "Rrun5W_cat Rrun5W_cat"
                 / 1fr 1fr;
  display: grid;
}

@media (width >= 1400px) {
  .Rrun5W_Categories {
    grid-template: "Rrun5W_edi Rrun5W_edi"
                   "Rrun5W_cat Rrun5W_pro"
                   "Rrun5W_cat Rrun5W_pro"
                   / 1fr 1fr;
  }
}

.Rrun5W_Categories__edit {
  grid-area: Rrun5W_edi;
}

.Rrun5W_Categories__products {
  grid-area: Rrun5W_pro;
}

.Rrun5W_Categories__cats {
  grid-area: Rrun5W_cat;
}

.Rrun5W_CatGroup {
  background: var(--white);
  border-radius: 7px;
  margin-bottom: 30px;
  padding: 0 20px 20px;
  box-shadow: 0 1px 15px #0000000d;
}

.Rrun5W_CatGroup__header {
  border-bottom: 1px solid var(--gray1);
  align-items: stretch;
  padding: 20px 0;
  display: flex;
}

.Rrun5W_CatGroup__headerText {
  margin-right: auto;
}

.Rrun5W_CatGroup__headerActions {
  align-items: center;
  margin-left: auto;
  display: flex;
}

.Rrun5W_CatGroup__title {
  font-size: 30px;
  line-height: 40px;
  font-weight: var(--semibold);
}

.Rrun5W_CatGroup__subtitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--regular);
  color: var(--gray6);
  margin-top: 5px;
}

.Rrun5W_CatGroup__count {
  vertical-align: middle;
  text-align: center;
  min-width: 1.5em;
  height: 1.5em;
  background: var(--gray2);
  box-sizing: border-box;
  border-radius: .6em;
  margin-left: .5em;
  padding-left: .5em;
  padding-right: .5em;
  font-size: 12px;
  line-height: 1.5em;
  display: inline-block;
}

.Rrun5W_CatGroup__groups {
  flex-flow: wrap;
  display: flex;
}

.Rrun5W_CatGroup__group {
  min-width: 30%;
  max-width: 50%;
  flex: 1;
  margin-top: 25px;
}

.Rrun5W_CatGroup__groupTitle {
  font-size: 20px;
  line-height: 30px;
  font-weight: var(--semibold);
}

.Rrun5W_CatGroup__groupItems {
  margin-top: 10px;
}

.Rrun5W_CatGroup__groupItem {
  font-size: 16px;
  line-height: 25px;
}

.rcqY6a_items {
  width: 100%;
}

.rcqY6a_noItems {
  padding: 0 calc(var(--pad-x)  + 20px);
  font-size: 20px;
}

.rcqY6a_header, .rcqY6a_itemValues {
  padding: 0 var(--pad-x);
  flex-direction: row;
  display: flex;
}

.rcqY6a_header, .rcqY6a_item {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.rcqY6a_item:hover {
  background: #f3f2f2;
  background: var(--gray1);
  --background-color: var(--gray1);
  cursor: default;
}

.rcqY6a_headerValue, .rcqY6a_itemValue {
  word-break: break-all;
  flex: 1;
}

.rcqY6a_headerValue {
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  padding: 20px 0 20px 20px;
  font-weight: 600;
  display: flex;
}

.rcqY6a_itemValue {
  vertical-align: middle;
  min-height: 30px;
  align-items: center;
  padding: 10px 0 10px 20px;
  line-height: 1.3;
  display: flex;
}

.rcqY6a_headerValue:first-child:not(.rcqY6a_isWide), .rcqY6a_itemValue:first-child:not(.rcqY6a_isWide) {
  flex: .5 .5;
}

.rcqY6a_headerValue:first-child, .rcqY6a_itemValue:first-child {
  padding-left: 0;
}

.rcqY6a_headerValue:last-child, .rcqY6a_itemValue:last-child {
  justify-content: flex-end;
}

.rcqY6a_isWide {
  flex: 3;
}

.rcqY6a_noWrap {
  white-space: nowrap;
}

.rcqY6a_headerValue.rcqY6a_sortedBy:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top: 8px solid #000;
  border-bottom: none;
  margin-left: 5px;
  display: block;
}

.rcqY6a_headerValue.rcqY6a_sortedBy.rcqY6a_asc:after {
  transform: rotate(180deg);
}

.rcqY6a_Indent {
  width: 0;
  height: 2em;
  background-image: url("corner-down-right.821c0617.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: auto 1em;
  margin: -.5em .25em -.5em 0;
  display: inline-block;
}

.rcqY6a_Indent[data-depth="1"] {
  width: 1em;
}

.rcqY6a_Indent[data-depth="2"] {
  width: 2em;
}

.rcqY6a_Indent[data-depth="3"] {
  width: 3em;
}

.rcqY6a_Indent[data-depth="4"] {
  width: 4em;
}

.rcqY6a_Indent[data-depth="5"] {
  width: 5em;
}

.rcqY6a_expander {
  padding: 20px var(--pad-x);
  border-bottom: 2px solid var(--gray2);
  cursor: initial;
}

.rcqY6a_link {
  max-width: 15em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Hwz_sG_Row {
  grid-gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Hwz_sG_Row--valign-center {
  align-items: center;
}

.Hwz_sG_Row--valign-top {
  align-items: flex-start;
}

.Hwz_sG_Row--valign-baseline {
  align-items: baseline;
}

.Hwz_sG_Column {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 20px;
  display: flex;
}

.Hwz_sG_Column--align-center {
  align-items: center;
}

.Hwz_sG_Column--align-left {
  align-items: flex-start;
}

.Hwz_sG_Column--align-stretch {
  align-items: stretch;
}

.Hwz_sG_TitleBar {
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
}

.Hwz_sG_TitleBar > * {
  margin-top: 0;
  margin-bottom: 0;
}

.Hwz_sG_Spread {
  flex: 1;
}

.W24b2q_KeyVals {
  font-size: 16px;
  line-height: 25px;
}

.W24b2q_KeyVals__item {
  background: var(--white);
  box-shadow: var(--tight-shadow);
  border-radius: 5px;
  flex-wrap: wrap;
  margin: 0 0 15px;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.W24b2q_KeyVals__item:last-child {
  margin-bottom: 0;
}

.W24b2q_KeyVals__key, .W24b2q_KeyVals__value {
  word-break: break-all;
  box-sizing: border-box;
  margin: 0;
  padding: 8px 12px;
}

.W24b2q_KeyVals__key {
  font-weight: var(--regular);
  min-width: 120px;
  flex: 1 0 30%;
}

.W24b2q_KeyVals__key span, .W24b2q_KeyVals__value span {
  font-weight: var(--regular);
  color: var(--gray4);
  font-size: 14px;
  line-height: 15px;
  display: inline-block;
}

.W24b2q_KeyVals__value {
  font-weight: var(--semibold);
  min-width: 250px;
  flex: 1 0 70%;
  font-size: 16px;
}

.W24b2q_KeyVals__list .W24b2q_KeyVals__list {
  margin-bottom: 10px;
  line-height: 20px;
}

.W24b2q_KeyVals__list--unordered {
  list-style-type: disc;
}

.W24b2q_KeyVals__list .W24b2q_KeyVals__list {
  margin-left: .75em;
}

.W24b2q_KeyVals__raw {
  font-size: 12px;
  line-height: 14px;
}

.D4iq2q_FilterBar {
  padding: 0 var(--pad-x);
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.YApBLq_Code {
  background: #0000001a;
  padding: 20px;
  display: block;
}

.YApBLq_Code pre {
  white-space: break-spaces;
  tab-size: 2;
  margin: 0;
  line-height: 1.5;
}

.Ww7ega_Content {
  padding: 20px var(--pad-x);
  display: block;
}

.-YA0na_ProductsSearch {
  --item-pad-x: 15px;
  --page-pad-x: 15px;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.-YA0na_ProductsSearch h1, h2, h3, p, input {
  margin: 0;
  padding: 0;
}

.-YA0na_header {
  z-index: var(--index-header);
  background: var(--background-color);
  padding: 20px var(--page-pad-x) 20px;
  grid-gap: 15px;
  grid-template: "-YA0na_title -YA0na_help -YA0na_help"
                 "-YA0na_search -YA0na_search -YA0na_control"
                 / auto 1fr auto;
  align-items: baseline;
  display: grid;
  position: sticky;
  top: 0;
  box-shadow: 0 0 5px #0000001a;
}

.-YA0na_title {
  grid-area: -YA0na_title;
  font-size: 30px;
}

.-YA0na_subtitle {
  grid-area: -YA0na_help;
  font-weight: normal;
}

.-YA0na_Control {
  grid-area: -YA0na_control;
  align-self: stretch;
  align-items: center;
  column-gap: 10px;
  margin-left: auto;
  display: flex;
}

.-YA0na_Search {
  padding: 10px var(--item-pad-x) 10px;
  background: #fff;
  border-radius: 5px;
  flex-wrap: wrap;
  grid-area: -YA0na_search;
  grid-template-columns: auto 1fr;
  grid-template-areas: "-YA0na_label -YA0na_input";
  display: grid;
  box-shadow: inset 0 -1px 1px #00000026;
}

.-YA0na_Search p {
  color: var(--gray3);
  grid-area: -YA0na_label;
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
}

.-YA0na_Search__input {
  box-sizing: border-box;
  border: none;
  outline: none;
  grid-area: -YA0na_input;
  font-size: 20px;
}

.-YA0na_Results {
  padding: 0 var(--page-pad-x);
  flex: 1;
  margin-top: 10px;
}

.-YA0na_Result {
  height: 150px;
  box-sizing: border-box;
  padding: 20px var(--item-pad-x);
  box-shadow: var(--card-shadow);
  grid-gap: 10px 20px;
  background: #fff;
  border-radius: 7px;
  grid-template: "-YA0na_image -YA0na_info" min-content
  / 70px 1fr;
  margin: 0 0 20px;
  display: grid;
}

.-YA0na_Result--show-attributes {
  height: 200px;
  grid-template-columns: 70px 1fr 300px;
  grid-template-areas: "-YA0na_image -YA0na_info -YA0na_attributes";
}

.-YA0na_Result a {
  text-decoration: none;
}

.-YA0na_Result a:hover {
  text-decoration: underline;
}

.-YA0na_Result__info {
  grid-area: -YA0na_info;
  overflow: hidden;
}

.-YA0na_Result__title {
  font-size: 20px;
  font-weight: var(--bold);
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;
  overflow: hidden;
}

.-YA0na_Result__category, .-YA0na_Result__brand, .-YA0na_Result__seller {
  font-weight: var(--semibold);
}

.-YA0na_Result__label {
  font-weight: var(--semibold);
  color: var(--gray5);
}

.-YA0na_Result__image {
  aspect-ratio: 1;
  border-radius: 4px;
  grid-area: -YA0na_image;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.-YA0na_Result__image img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.-YA0na_Result__id {
  color: var(--gray5);
  vertical-align: baseline;
  margin-right: 10px;
  font-size: 16px;
}

.-YA0na_Result__id::selection {
  background: gold;
}

.-YA0na_Result__searchString {
  order: 2;
  grid-area: -YA0na_search;
  align-self: flex-end;
}

.-YA0na_Result__score {
  order: 1;
  grid-area: -YA0na_score;
  align-self: flex-start;
}

.-YA0na_Result__attributes {
  grid-area: -YA0na_attributes;
  font-size: 14px;
}

.-YA0na_Result__attributeKey {
  color: var(--gray5);
  font-weight: var(--semibold);
}

.-YA0na_TypingPrompt {
  text-align: center;
  margin: 30px 0;
  font-size: 30px;
}

.CM3Ycq_ContentEditor {
  border-radius: 5px;
  padding-bottom: 20px;
}

.CM3Ycq_MetaEditor {
  grid-gap: 20px;
  flex-direction: column;
  display: flex;
}

.ia0eEW_BrandPageEditor {
  column-gap: var(--grid-column-gap);
  row-gap: var(--section-spacing);
  grid-template: "ia0eEW_meta"
                 "ia0eEW_content"
                 / 1fr;
  display: grid;
}

.ia0eEW_BrandPageEditor__basic {
  grid-area: ia0eEW_basic;
}

.ia0eEW_BrandPageEditor__page {
  grid-area: ia0eEW_page;
}

.ia0eEW_BrandPageEditor__meta {
  grid-area: ia0eEW_meta;
}

.ia0eEW_BrandPageEditor__content {
  grid-area: ia0eEW_content;
}

._9-CfCq_CreateNewPost {
  margin-top: 10vh;
  font-size: 20px;
}

._9-CfCq_BlogPostEditor {
  column-gap: var(--grid-column-gap);
  row-gap: var(--section-spacing);
  grid-template: "_9-CfCq_meta"
                 "_9-CfCq_content"
                 / 1fr;
  display: grid;
}

._9-CfCq_BlogPostEditor__basic {
  grid-area: _9-CfCq_basic;
}

._9-CfCq_BlogPostEditor__page {
  grid-area: _9-CfCq_page;
}

._9-CfCq_BlogPostEditor__meta {
  grid-area: _9-CfCq_meta;
}

._9-CfCq_BlogPostEditor__content {
  grid-area: _9-CfCq_content;
}

.npG-vW_InlineDiff__part {
  color: inherit;
}

.npG-vW_InlineDiff__part--added {
  background-color: var(--green-light);
  border-radius: 2px;
}

.npG-vW_InlineDiff__part--removed {
  background-color: var(--red-light);
  border-radius: 2px;
}

.Ep8y_a_ActionCard {
  --pad-x: 20px;
  border-radius: 7px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 14px #0000000f, 0 1px 1px #00000014;
}

.Ep8y_a_ActionCard:last-child {
  margin-bottom: 0;
}

.Ep8y_a_Header {
  width: 100%;
  box-sizing: border-box;
  min-height: 75px;
  padding: 25px var(--pad-x) 0;
  background: var(--secondary-gray);
  border: 1px solid #0000000d;
  border-bottom: none;
  border-radius: 7px 7px 0 0;
  align-items: baseline;
  column-gap: 10px;
  line-height: 30px;
  display: flex;
}

.Ep8y_a_Footer {
  width: 100%;
  box-sizing: border-box;
  min-height: 75px;
  padding: 25px var(--pad-x);
  background: var(--secondary-gray);
  border: 1px solid #0000000d;
  border-bottom: none;
  border-radius: 0 0 7px 7px;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  display: flex;
}

.Ep8y_a_Heading {
  font-size: 22px;
  line-height: inherit;
  font-weight: 500;
}

.Ep8y_a_Heading__link {
  text-decoration: none;
}

.Ep8y_a_Heading__link[href]:hover {
  text-decoration: underline;
}

.Ep8y_a_SubHeading {
  font-size: 20px;
  line-height: inherit;
  color: var(--gray4);
  font-weight: 400;
}

.Ep8y_a_SubHeading__link {
  text-decoration: none;
}

.Ep8y_a_SubHeading__link[href]:hover {
  text-decoration: underline;
}

.Ep8y_a_Source {
  font-size: 20px;
  line-height: inherit;
  white-space: nowrap;
  font-weight: 400;
  text-decoration: none;
}

.Ep8y_a_Source:hover {
  text-decoration: underline;
}

.Ep8y_a_Source:after {
  content: "";
  --size: 15px;
  height: var(--size);
  width: var(--size);
  background-image: url("external-link.1c99e7ea.svg");
  background-size: var(--size) var(--size);
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 8px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.Ep8y_a_Spread {
  flex: 1;
}

.Ep8y_a_Body {
  width: 100%;
  box-sizing: border-box;
  background: var(--white);
  padding: 25px 0;
  box-shadow: inset 0 2px 1px -1px #0000001a;
}

.Ep8y_a_Slot {
  width: 100%;
  background: var(--white);
}

.Ep8y_a_Slot__contents {
  box-sizing: border-box;
  grid-template-columns: 1fr auto;
  grid-auto-rows: auto;
  row-gap: 20px;
  padding: 25px 20px;
  display: grid;
  box-shadow: inset 0 2px 1px -1px #0000001a;
}

.Ep8y_a_SlotHeader {
  min-height: 25px;
  grid-area: 1 / 1;
  align-items: center;
  column-gap: 10px;
  display: flex;
}

.Ep8y_a_SlotTitle {
  font-size: 18px;
  font-weight: var(--bold);
  line-height: 20px;
  display: inline;
}

.Ep8y_a_SlotHeaderSub {
  color: var(--gray5);
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
}

.Ep8y_a_SlotGroup {
  box-sizing: border-box;
  grid-area: auto / 1;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  row-gap: 5px;
  padding: 0;
  display: grid;
}

.Ep8y_a_SlotGroup:only-of-type {
  row-gap: 0;
}

.Ep8y_a_SlotGroup--has-hover-style:hover {
  background: var(--off-white);
  border-radius: 5px;
  margin: -10px;
  padding: 10px;
}

.Ep8y_a_SlotActions {
  grid-area: 1 / 2 / 3;
  align-self: center;
  column-gap: 10px;
  margin-left: 20px;
  display: flex;
}

.Ep8y_a_SlotActions--align-center {
  align-self: center;
}

.Ep8y_a_SlotActions--align-end {
  align-self: end;
}

.Ep8y_a_SlotBody {
  word-break: break-word;
  grid-area: 2 / 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  margin: 0;
  display: grid;
}

.p1VHBq_Stats {
  --row-height: 40px;
  --row-gap: 10px;
  grid-template: "p1VHBq_legends p1VHBq_bars"
  / auto 1fr;
  display: grid;
}

.p1VHBq_Legends, .p1VHBq_Bars {
  grid-row-gap: var(--row-gap);
  grid-template-columns: 1fr;
  display: grid;
}

.p1VHBq_Legends {
  grid-area: p1VHBq_legends;
}

.p1VHBq_Bars {
  display: grid;
}

.p1VHBq_Legend, .p1VHBq_Bar {
  height: var(--row-height);
  box-sizing: border-box;
  align-items: center;
  padding: 0 15px;
  display: flex;
}

.p1VHBq_Legend {
  background: var(--gray1);
  border-radius: 4px 0 0 4px;
}

.p1VHBq_Bar {
  background: var(--gray2);
  font-weight: var(--bold);
  border-radius: 0 4px 4px 0;
}

.TniCta_FilterBar {
  grid-gap: 30px;
  margin-bottom: 40px;
  display: flex;
}

.TniCta_FilterBar > * {
  flex: 1;
}

.TniCta_FilterBar__select {
  background: var(--gray1);
  border-radius: 5px;
  padding: 10px;
}

.TniCta_MatchGrid {
  grid-gap: 20px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.TniCta_MatchPreview {
  grid-gap: 20px;
  background: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 7px;
  grid-template: "TniCta_query TniCta_answer"
                 "TniCta_result TniCta_answer"
                 / 1fr auto auto;
  padding: 20px;
  display: grid;
}

.TniCta_MatchPreview__row {
  grid-template-columns: 60px 1fr;
  align-items: center;
  display: grid;
}

.TniCta_MatchPreview__row:hover {
  text-decoration: none;
}

.TniCta_MatchPreview__row--query {
  grid-area: TniCta_query;
}

.TniCta_MatchPreview__row--result {
  grid-area: TniCta_result;
}

.TniCta_MatchPreview__label {
  font-size: 14px;
  line-height: 15px;
  font-weight: var(--bold);
  color: var(--gray4);
}

.TniCta_MatchPreview__text {
  font-size: 20px;
  line-height: 25px;
  font-weight: var(--semibold);
  color: var(--black);
  vertical-align: top;
  align-items: center;
  display: inline-block;
}

.TniCta_MatchPreview__row:hover .TniCta_MatchPreview__text {
  text-decoration: underline;
}

.TniCta_MatchPreview__answer {
  flex-direction: column;
  grid-area: TniCta_answer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.TniCta_MatchPreview__answerAction {
  font-size: 24px;
  font-weight: var(--bold);
}

.TniCta_MatchPreview__answerReason {
  font-size: 20px;
  font-weight: var(--bold);
  grid-area: TniCta_answer;
  align-items: center;
  display: flex;
}

.pdzOOq_MatchSplit {
  display: flex;
}

.pdzOOq_MatchSplit > :first-child {
  border-right: 1px solid var(--light-gray);
}

.pdzOOq_MatchPreview {
  text-align: center;
  flex: 50%;
  padding: 0 20px;
}

.pdzOOq_MatchPreview__title {
  font-size: 18px;
  line-height: 20px;
  font-weight: var(--bold);
}

.pdzOOq_MatchPreview__id {
  font-size: 14px;
  line-height: inherit;
  color: var(--gray4);
  margin: 5px auto 0;
  line-height: 15px;
}

.pdzOOq_MatchPreview__idLink {
  color: inherit;
}

.pdzOOq_MatchPreview__image {
  height: 160px;
  width: 160px;
  margin: 10px auto 35px;
  position: relative;
}

.pdzOOq_MatchPreview__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pdzOOq_MatchPreview__heading {
  font-size: 20px;
  font-weight: var(--bold);
  line-height: 25px;
}

.pdzOOq_MatchPreview__editLink {
  color: var(--gray2);
  font-size: 14px;
  line-height: inherit;
  margin-left: .25em;
  position: absolute;
}

.pdzOOq_MatchPreview__link {
  color: var(--gray4);
  word-break: break-all;
  margin-top: auto;
  font-size: 12px;
  text-decoration: none;
  display: inline-block;
}

.pdzOOq_MatchPreview__link:after {
  content: "";
  --size: 10px;
  height: var(--size);
  width: var(--size);
  opacity: .4;
  background-image: url("external-link.1c99e7ea.svg");
  background-size: var(--size) var(--size);
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 4px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.pdzOOq_MatchPreview__link:hover {
  color: var(--gray4);
  text-decoration: underline;
}

.pdzOOq_ReasonPopover {
  width: 240px;
  height: auto;
  z-index: 100;
  background: var(--white);
  counter-reset: items;
  border: 1px solid var(--gray1);
  border-radius: 7px;
  animation: .15s pdzOOq_popover-intro;
  position: absolute;
  bottom: 50px;
  left: 40%;
  overflow: hidden;
  box-shadow: 0 1px 10px #00000026;
}

.pdzOOq_ReasonPopover__title {
  font-size: 16px;
  line-height: 40px;
  font-weight: var(--semibold);
  border-bottom: 1px solid var(--gray1);
  text-align: center;
}

.pdzOOq_ReasonPopover__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pdzOOq_ReasonPopover__item {
  font-size: 14px;
  font-weight: var(--bold);
  min-height: 32px;
  counter-increment: items;
  border-radius: 5px;
  align-items: center;
  margin: 4px;
  padding: 0 16px;
  line-height: 20px;
  display: flex;
}

.pdzOOq_ReasonPopover__item:hover {
  background: var(--gray1);
  cursor: pointer;
}

.pdzOOq_ReasonPopover__item:before {
  content: counter(items);
  color: var(--gray4);
  font-weight: var(--regular);
  margin-right: 10px;
  display: inline-block;
}

.pdzOOq_ReasonPopover__otherItem {
  border-top: 1px solid var(--gray1);
}

.pdzOOq_ReasonPopover__otherForm {
  justify-content: stretch;
  align-items: stretch;
  display: flex;
}

.pdzOOq_ReasonPopover__otherInput {
  color: var(--gray6);
  font-size: 14px;
  line-height: 40px;
  font-weight: var(--regular);
  border: none;
  outline: none;
  flex: 1;
  padding: 0 16px;
}

.pdzOOq_ReasonPopover__otherButton {
  text-indent: -999px;
  width: 40px;
  opacity: .75;
  background: url("submit-arrow.a23d2936.svg") center / 16px no-repeat;
  border: none;
  transition: opacity .2s;
}

.pdzOOq_ReasonPopover__otherButton:hover {
  cursor: pointer;
  opacity: 1;
}

.pdzOOq_ReasonPopover__otherInput:placeholder-shown + .pdzOOq_ReasonPopover__otherButton {
  opacity: 0;
}

@keyframes pdzOOq_popover-intro {
  0% {
    opacity: 0;
    transform: scale(.8)translateY(30px);
  }

  70% {
    opacity: 1;
  }
}

.pdzOOq_BigPop {
  text-align: center;
  pointer-events: none;
  transform-origin: 0 0;
  font-size: 80px;
  line-height: .9;
  animation: .3s both pdzOOq_big-pop;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes pdzOOq_big-pop {
  0% {
    opacity: 0;
    transform: scale(.5)translate(-50%, -50%);
  }

  20% {
    opacity: 1;
    transform: scale(1)translate(-50%, -50%);
  }

  70% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: scale(1.5)translate(-50%, -50%);
  }
}

.P1UT7a_GoldSifter__setItem {
  font-size: 18px;
  font-weight: var(--semibold);
  align-items: center;
  margin: 0 0 10px;
  display: flex;
}

.P1UT7a_GoldSifter__setItemButton {
  margin-left: 10px;
}

.WbXV9W_Banner {
  z-index: 20;
  background: var(--white);
  box-shadow: var(--wide-shadow);
  border-radius: 11px;
  margin-bottom: 40px;
  padding: 30px 0;
}

.WbXV9W_Banner__content {
  align-items: center;
  padding: 0 40px;
  display: flex;
}

.WbXV9W_Banner__text {
  font-size: 25px;
  line-height: 25px;
  font-weight: var(--semibold);
  margin-right: auto;
}

.WbXV9W_Banner__actions {
  align-items: center;
  margin-left: auto;
  display: flex;
}

.ov1OtG_CategoryPreview {
  grid-template: "ov1OtG_title ov1OtG_products"
                 "ov1OtG_crumbs ov1OtG_products"
                 "ov1OtG_other ov1OtG_products" 1fr
                 / 1fr 1fr;
  display: grid;
}

.ov1OtG_CategoryPreview__title {
  grid-area: ov1OtG_title;
  font-size: 20px;
}

.ov1OtG_CategoryPreview__title a {
  text-decoration: none;
}

.ov1OtG_CategoryPreview__title a:hover {
  text-decoration: underline;
}

.ov1OtG_CategoryPreview__title a:after {
  content: "";
  --size: .8em;
  vertical-align: baseline;
  height: var(--size);
  width: var(--size);
  background-image: url("external-link.1c99e7ea.svg");
  background-size: var(--size) var(--size);
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 8px;
  display: inline-block;
}

.ov1OtG_CategoryPreview__crumbs {
  grid-area: ov1OtG_crumbs;
  margin-top: 10px;
}

.ov1OtG_CategoryPreview__crumb {
  text-decoration: none;
}

.ov1OtG_CategoryPreview__crumb:hover {
  text-decoration: underline;
}

.ov1OtG_CategoryPreview__crumb:after {
  content: " / ";
  margin: 0 .5em;
  display: inline-block;
}

.ov1OtG_CategoryPreview__crumb:last-child:after {
  content: none;
}

.ov1OtG_CategoryPreview__products {
  grid-area: ov1OtG_products;
}

.ov1OtG_CategoryPreview__sectionTitle {
  font-size: 16px;
  font-weight: var(--bold);
  margin-bottom: 10px;
}

.ov1OtG_CategoryPreview__showMore {
  font-size: 12px;
}

._2NxDbG_Question {
  background: var(--white);
  box-shadow: var(--wide-shadow);
  border-radius: 11px;
  margin-bottom: 40px;
  padding: 25px 20px;
}

.YY7j3q_Row {
  min-height: 50px;
  background: var(--white);
  box-sizing: border-box;
  box-shadow: var(--tight-shadow);
  border-radius: 7px;
  align-items: center;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 22px;
  display: flex;
}

.YY7j3q_Row + .YY7j3q_Row {
  margin-top: 10px;
}

.YY7j3q_Row__ordinal {
  color: var(--gray4);
  flex: none;
  margin-right: 12px;
}

.YY7j3q_Row__text {
  font-weight: var(--semibold);
  flex: 1;
  margin-right: auto;
  padding-right: 50px;
  text-decoration: none;
  overflow: hidden;
}

.YY7j3q_Row__text[href]:hover {
  text-decoration: underline;
}

.YY7j3q_Row__raw {
  word-break: break-all;
  font-size: 12px;
}

.YY7j3q_Row__keyValueExtra {
  flex-wrap: wrap;
  flex: 1;
  font-size: 18px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.YY7j3q_Row__keyValueExtra[href]:hover .YY7j3q_Row__extra {
  text-decoration: underline;
}

.YY7j3q_Row__key {
  font-weight: var(--bold);
}

.YY7j3q_Row__value {
  text-overflow: ellipsis;
  color: var(--gray4);
  font-weight: var(--semibold);
  margin-left: 10px;
  overflow: hidden;
}

.YY7j3q_Row__extra {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 100%;
  margin-top: 10px;
  font-size: 14px;
  overflow: hidden;
}

.YY7j3q_Row__actions {
  margin-left: auto;
  margin-right: 0;
  display: flex;
}

.FfjF7W_PickerPreview {
  background: var(--white);
  border-radius: 7px;
  margin-bottom: 30px;
  padding: 20px;
}

.FfjF7W_PickerPreview__title {
  font-size: 16px;
  font-weight: var(--bold);
  margin-bottom: 5px;
}

.FfjF7W_PickerPreview__text {
  font-size: 20px;
  font-weight: var(--bold);
}

.FfjF7W_PickerPreview__href {
  color: var(--gray4);
  word-break: break-all;
  font-size: 16px;
  text-decoration: none;
}

.FfjF7W_PickerPreview__href:hover {
  text-decoration: underline;
}

.FfjF7W_PickerPreview__crumbs {
  grid-gap: 10px;
  font-size: 26px;
  display: flex;
}

.FfjF7W_PickerPreview__crumb:after {
  content: "/";
  margin-left: 10px;
  display: inline-block;
}

.FfjF7W_PickerPreview__crumb:last-child:after {
  content: none;
}

.FfjF7W_PickerInput {
  grid-gap: 20px;
  grid-template-columns: 1fr;
  display: grid;
}

.LChTDG_Expander {
  background: var(--white);
  box-shadow: var(--wide-shadow);
  border-radius: 11px;
}

.LChTDG_Expander + .LChTDG_Expander {
  margin-top: 20px;
}

.LChTDG_Expander__header {
  cursor: pointer;
  --line-height: 30px;
  align-items: end;
  padding: 15px;
  display: flex;
}

.LChTDG_Expander__title {
  font-size: 25px;
  line-height: var(--line-height);
  display: inline-block;
}

.LChTDG_Expander__prompt {
  margin-left: 10px;
  display: none;
}

.LChTDG_Expander__header:hover .LChTDG_Expander__prompt {
  text-decoration: underline;
}

.LChTDG_Expander__icon {
  width: var(--line-height);
  height: var(--line-height);
  opacity: .5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
  margin-left: auto;
  transition: opacity .15s;
  display: inline-block;
}

.LChTDG_Expander__header:hover .LChTDG_Expander__icon {
  opacity: 1;
}

.LChTDG_Expander__icon--open {
  background-image: url("maximize.d726b44c.svg");
}

.LChTDG_Expander__icon--close {
  background-image: url("minimize.55a0e76a.svg");
}

.LChTDG_Expander__contents {
  padding: 0 20px;
}

._QBKcq_MatchList__cutoff {
  font-size: 14px;
  font-weight: var(--bold);
  border: none;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

._QBKcq_MatchList__cutoff:before, ._QBKcq_MatchList__cutoff:after {
  content: "";
  height: 1px;
  background: red;
  flex: 1;
  margin: 0 10px;
}

._QBKcq_MatchItem {
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  min-height: 20px;
  background: var(--white);
  box-shadow: var(--wide-shadow);
  border-radius: 7px;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 1fr auto;
  padding: 15px;
  font-size: 18px;
  line-height: 25px;
  display: grid;
}

._QBKcq_MatchItem + ._QBKcq_MatchItem {
  margin-top: 10px;
}

._QBKcq_MatchItem__media {
  width: 65px;
  height: 65px;
  background: var(--off-white);
  text-align: left;
  border-radius: 3px;
  grid-area: 1 / 1 / 3;
  overflow: hidden;
  box-shadow: 0 1px 1px #0000001a;
}

._QBKcq_MatchItem__media img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

._QBKcq_MatchItem__titles {
  grid-area: 1 / 2;
  align-self: center;
  align-items: flex-start;
  display: flex;
}

._QBKcq_MatchItem__title {
  font-weight: var(--bold);
  font-size: inherit;
  flex: 100%;
  margin: 0;
  padding: 0;
  line-height: 20px;
}

._QBKcq_MatchItem__link {
  font: inherit;
  color: inherit;
}

._QBKcq_MatchItem__id {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--semibold);
  color: var(--gray4);
  display: inline-block;
}

._QBKcq_MatchItem__meta {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--semibold);
  color: var(--gray4);
  grid-gap: 10px;
  display: flex;
}

._QBKcq_MatchItem__id {
  display: inline-block;
}

._QBKcq_MatchItem__rank {
  color: var(--black);
  font-weight: var(--bold);
  display: inline-block;
}

._QBKcq_MatchItem__info {
  grid-area: 1 / 3 / 3;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._QBKcq_MatchItem__info img {
  height: 26px;
}

._QBKcq_MatchItem__extra {
  grid-area: 3 / 1 / auto / 4;
  font-size: 14px;
  line-height: 20px;
}

._QBKcq_MatchItem__extraTitle {
  font-weight: var(--bold);
}

._QBKcq_MatchItem__scores {
  margin-top: 5px;
}

._QBKcq_MatchItem__scoreItem {
  font-size: 13px;
  font-weight: var(--semibold);
  background: var(--gray1);
  border-radius: 3px;
  margin-right: .5em;
  padding: 0 5px;
  display: inline-block;
}

._QBKcq_MatchItem__filters {
  margin-top: 5px;
}

.-xkOqW_ServicesItem {
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  min-height: 20px;
  background: var(--white);
  box-shadow: var(--wide-shadow);
  border-radius: 7px;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 1fr auto;
  padding: 15px;
  font-size: 18px;
  line-height: 25px;
  display: grid;
}

.-xkOqW_ServicesItem + .-xkOqW_ServicesItem {
  margin-top: 10px;
}

.-xkOqW_ServicesItem__media {
  width: 65px;
  height: 65px;
  background: var(--off-white);
  text-align: left;
  border-radius: 3px;
  grid-area: 1 / 1 / 3;
  box-shadow: 0 1px 1px #0000001a;
}

.-xkOqW_ServicesItem__media img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.-xkOqW_ServicesItem__titles {
  grid-area: 1 / 2;
  align-self: center;
  align-items: flex-start;
  display: flex;
}

.-xkOqW_ServicesItem__title {
  font-weight: var(--bold);
  font-size: inherit;
  flex: 100%;
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.-xkOqW_ServicesItem__link {
  font: inherit;
  color: inherit;
}

.-xkOqW_ServicesItem__type {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--semibold);
  color: var(--gray4);
  display: inline-block;
}

.-xkOqW_ServicesItem__meta {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--semibold);
  color: var(--gray4);
  grid-gap: 10px;
  display: flex;
}

.-xkOqW_ServicesItem__id {
  display: inline-block;
}

.-xkOqW_ServicesItem__rank {
  color: var(--black);
  font-weight: var(--bold);
  display: inline-block;
}

.-xkOqW_ServicesItem__info {
  grid-area: 1 / 3 / 3;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.-xkOqW_ServicesItem__info img {
  height: 26px;
}

.-xkOqW_ServicesItem__extra {
  grid-area: 3 / 1 / auto / 4;
  font-size: 14px;
  line-height: 20px;
}

.-xkOqW_ServicesItem__extraTitle {
  font-weight: var(--bold);
}

.-xkOqW_ServicesItem__patterns {
  margin-top: 5px;
}

._nJHbq_CategoriesItem {
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  min-height: 20px;
  background: var(--white);
  box-shadow: var(--wide-shadow);
  border-radius: 7px;
  grid-template-areas: "_nJHbq_titles"
                       "_nJHbq_extra";
  padding: 15px;
  font-size: 18px;
  line-height: 25px;
  display: grid;
}

._nJHbq_CategoriesItem + ._nJHbq_CategoriesItem {
  margin-top: 10px;
}

._nJHbq_CategoriesItem__titles {
  grid-gap: 10px;
  grid-area: _nJHbq_titles;
  align-items: baseline;
  display: flex;
}

._nJHbq_CategoriesItem__title {
  font-weight: var(--bold);
  font-size: inherit;
  align-items: flex-start;
  display: flex;
}

._nJHbq_CategoriesItem__score {
  font-size: 14px;
  font-weight: var(--semibold);
  color: var(--gray4);
  grid-area: _nJHbq_score;
}

._nJHbq_CategoriesItem__link {
  font: inherit;
  color: inherit;
}

._nJHbq_CategoriesItem__type {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--semibold);
  color: var(--gray4);
  display: inline-block;
}

._nJHbq_CategoriesItem__id {
  display: inline-block;
}

._nJHbq_CategoriesItem__rank {
  color: var(--black);
  font-weight: var(--bold);
  display: inline-block;
}

._nJHbq_CategoriesItem__extra {
  grid-area: _nJHbq_extra;
  font-size: 14px;
  line-height: 20px;
}

._nJHbq_CategoriesItem__extraTitle {
  font-weight: var(--bold);
}

._9wpQ4G_TabSwitcher {
  box-shadow: var(--tight-shadow);
  color: var(--text-black);
  vertical-align: text-bottom;
  background: var(--off-white);
  border-radius: 7px;
  display: inline-flex;
  overflow: hidden;
}

._9wpQ4G_TabSwitcher__tab {
  font-size: 14px;
  line-height: 15px;
  font-weight: var(--bold);
  letter-spacing: .025em;
  cursor: pointer;
  appearance: none;
  border: 4px solid var(--off-white);
  box-sizing: content-box;
  color: var(--gray6);
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 9px;
  padding: 7px 14px;
  transition: background-color .15s;
}

._9wpQ4G_TabSwitcher:hover ._9wpQ4G_TabSwitcher__tab {
  background: none;
}

._9wpQ4G_TabSwitcher__tab:first-child {
  border-left-width: 4px;
}

._9wpQ4G_TabSwitcher__tab:last-child {
  border-right-width: 4px;
}

._9wpQ4G_TabSwitcher__tab--is-selected, ._9wpQ4G_TabSwitcher__tab:hover, ._9wpQ4G_TabSwitcher:hover ._9wpQ4G_TabSwitcher__tab:hover {
  background: var(--gray2);
  color: var(--text-black);
}

._4m6MnW_Inspect {
  column-gap: var(--grid-column-gap);
  row-gap: var(--section-spacing);
  grid-template: "_4m6MnW_sca _4m6MnW_res"
                 "_4m6MnW_ana _4m6MnW_cat"
                 / 1fr 1fr;
  margin-bottom: 50px;
  display: grid;
}

@media (width >= 1500px) {
  ._4m6MnW_Inspect {
    grid-template: "_4m6MnW_sca _4m6MnW_ana _4m6MnW_res"
                   "_4m6MnW_cat _4m6MnW_ana _4m6MnW_res"
                   / 1fr 1fr 1fr;
  }
}

._4m6MnW_Inspect__section[data-section="Scan"] {
  grid-area: _4m6MnW_sca;
}

._4m6MnW_Inspect__section[data-section="Analysis"] {
  grid-area: _4m6MnW_ana;
}

._4m6MnW_Inspect__section[data-section="Results"] {
  grid-area: _4m6MnW_res;
}

._4m6MnW_Inspect__section[data-section="Categories"] {
  grid-area: _4m6MnW_cat;
}

._4m6MnW_Inspect__image {
  height: 200px;
  background: var(--white);
  box-shadow: var(--tight-shadow);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  position: relative;
}

._4m6MnW_Inspect__image img {
  max-width: 100%;
  max-height: 100%;
}

._4m6MnW_Results__source {
  color: var(--gray4);
  font-weight: var(--semibold);
  margin-bottom: 20px;
  font-size: 16px;
}

._4m6MnW_Results__source em {
  color: var(--black);
  font-style: normal;
  font-weight: var(--bold);
}

.SIUakq_Paginate {
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px;
  display: flex;
}

.BjciMa_ScanItem {
  background: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 7px;
  grid-template: "BjciMa_title BjciMa_stats BjciMa_actions"
                 "BjciMa_meta BjciMa_stats BjciMa_actions"
                 / 3fr 2fr auto;
  gap: 5px 10px;
  margin-bottom: 30px;
  padding: 20px 30px 20px 20px;
  display: grid;
}

.BjciMa_ScanItem__title {
  font-size: 18px;
  font-weight: var(--bold);
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-area: BjciMa_title;
  line-height: 25px;
  overflow: hidden;
}

.BjciMa_ScanItem__meta {
  font-size: 14px;
  font-weight: var(--regular);
  grid-area: BjciMa_meta;
  column-gap: 20px;
  margin-top: 5px;
  line-height: 15px;
  display: flex;
}

.BjciMa_ScanItem__time {
  color: var(--black);
}

.BjciMa_ScanItem__id {
  color: var(--gray4);
}

.BjciMa_ScanItem__stats {
  font-size: 18px;
  font-weight: var(--bold);
  grid-area: BjciMa_stats;
  align-items: center;
  column-gap: 40px;
  line-height: 25px;
  display: flex;
}

.BjciMa_ScanItem__stats a {
  text-decoration: none;
  display: block;
}

.BjciMa_ScanItem__stats a:hover {
  text-decoration: underline;
}

.BjciMa_ScanItem__actions {
  grid-area: BjciMa_actions;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.BjciMa_red {
  color: var(--red-dark);
}

.BjciMa_fade {
  color: var(--gray2);
}

.BjciMa_ScanResultItem {
  background: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 7px;
  grid-template-rows: auto auto min-content;
  grid-template-areas: "BjciMa_key"
                       "BjciMa_value"
                       "BjciMa_meta";
  gap: 5px 10px;
  margin-bottom: 20px;
  padding: 20px 30px 20px 20px;
  display: grid;
}

.BjciMa_ScanResultItem__key {
  font-size: 16px;
  font-weight: var(--bold);
  grid-area: BjciMa_key;
  line-height: 25px;
}

.BjciMa_ScanResultItem__value {
  font-size: 18px;
  font-weight: var(--regular);
  white-space: pre-line;
  grid-area: BjciMa_value;
  line-height: 25px;
}

.BjciMa_ScanResultItem__index {
  color: var(--gray5);
  font-weight: var(--regular);
}

.BjciMa_ScanResultItem__meta {
  background: var(--off-white);
  border-radius: 3px;
  grid-area: BjciMa_meta;
  margin: 0 -10px;
  padding: 5px 10px;
  font-size: 14px;
}

.BjciMa_ScanResultItem__metaItem {
  display: block;
}

.BjciMa_ScanErrorItem {
  background: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 7px;
  grid-template: "BjciMa_message BjciMa_link"
                 "BjciMa_stack BjciMa_stack"
                 / 1fr auto;
  row-gap: 5px;
  margin-bottom: 20px;
  padding: 20px 0 0;
  display: grid;
  overflow: scroll;
}

.BjciMa_ScanErrorItem--with-context {
  grid-template-areas: "BjciMa_context BjciMa_link"
                       "BjciMa_message BjciMa_message"
                       "BjciMa_stack BjciMa_stack";
}

.BjciMa_ScanErrorItem__message {
  font-size: 16px;
  font-weight: var(--bold);
  color: var(--red-dark);
  grid-area: BjciMa_message;
  padding: 0 20px 10px;
  line-height: 25px;
}

.BjciMa_ScanErrorItem__context {
  font-size: 16px;
  font-weight: var(--bold);
  color: var(--black);
  grid-area: BjciMa_context;
  padding: 0 20px 10px;
  line-height: 25px;
}

.BjciMa_ScanErrorItem__link {
  font-weight: var(--bold);
  grid-area: BjciMa_link;
  padding-right: 20px;
}

.BjciMa_ScanErrorItem__stack {
  font-size: 14px;
  font-weight: var(--regular);
  background: var(--secondary-gray);
  grid-area: BjciMa_stack;
  padding: 10px 20px 15px;
  line-height: 25px;
}

.BjciMa_ScanErrorItem__stack pre {
  color: var(--black);
  tab-width: 2;
  font-family: monospace;
  font-size: 12px;
  line-height: 15px;
}

.k9Zaiq_JSONPreview pre {
  background: var(--gray1);
  color: var(--black);
  tab-size: 2;
  cursor: text;
  border-radius: 7px;
  margin: 0;
  padding: 15px 10px;
  font-family: monospace;
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
}

.k9Zaiq_JSONPreview--closed pre {
  max-height: 15px;
  cursor: pointer;
}

.k9Zaiq_JSONPreview__toggle {
  font-size: 14px;
  font-weight: var(--semibold);
  color: var(--gray4);
  cursor: pointer;
  margin: 5px 10px 0;
}

.BWk_cG_ListItem {
  grid-column-gap: 10px;
  min-height: 20px;
  background: var(--white);
  box-shadow: var(--wide-shadow);
  border-radius: 7px;
  grid-template: "BWk_cG_thumb BWk_cG_title BWk_cG_stats BWk_cG_actions" 1fr
                 "BWk_cG_thumb BWk_cG_metas BWk_cG_stats BWk_cG_actions" 1fr
                 / auto 1fr auto;
  padding: 15px;
  font-size: 18px;
  line-height: 25px;
  display: grid;
}

.BWk_cG_ListItem + .BWk_cG_ListItem {
  margin-top: 10px;
}

.BWk_cG_ListItem__thumb {
  width: 65px;
  height: 65px;
  background: var(--off-white);
  text-align: left;
  border-radius: 3px;
  grid-area: BWk_cG_thumb / 1;
  overflow: hidden;
  box-shadow: 0 1px 1px #0003;
}

.BWk_cG_ListItem__thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.BWk_cG_ListItem__titles {
  grid-gap: 10px;
  grid-area: BWk_cG_title;
  align-items: baseline;
  display: flex;
}

.BWk_cG_ListItem__title {
  font-weight: var(--bold);
  font-size: inherit;
  margin: 5px 0 0;
  padding: 0;
  line-height: 20px;
}

.BWk_cG_ListItem__tags {
  display: inline-block;
}

.BWk_cG_ListItem__link {
  font: inherit;
  color: inherit;
}

.BWk_cG_ListItem__metas {
  grid-gap: 10px;
  grid-area: BWk_cG_metas;
  align-items: baseline;
  display: flex;
}

.BWk_cG_ListItem__stats {
  grid-gap: 10px;
  grid-area: BWk_cG_stats;
  align-items: center;
  display: flex;
}

.BWk_cG_ListItem__actions {
  grid-area: BWk_cG_actions;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
  display: flex;
}

.BWk_cG_Tag {
  font-size: 12px;
  font-weight: var(--bold);
  border-radius: 2px;
  padding: 2px 8px 1px;
  line-height: 15px;
}

.BWk_cG_Tag[data-color="gray"] {
  background-color: var(--gray1);
}

.BWk_cG_Tag[data-color="yellow"] {
  background-color: var(--yellow);
}

.BWk_cG_Meta {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--semibold);
  margin-top: 5px;
}

.BWk_cG_Meta[data-color="black"] {
  color: var(--black);
}

.BWk_cG_Meta[data-color="gray"] {
  color: var(--gray4);
}

.BWk_cG_Stat {
  font-size: 16px;
  line-height: 25px;
}

.BWk_cG_Stat[data-color="black"] {
  color: var(--black);
}

.BWk_cG_Stat[data-color="red"] {
  color: var(--red-dark);
}

._8W4U5a_SortableListItem {
  margin: 0 0 10px;
  list-style-type: none;
}

._3NWXiW_ItemEditor {
  margin-top: 50px;
}

._3NWXiW_AddNewItem {
  grid-gap: 10px;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

._3NWXiW_Items {
  margin-top: 30px;
}

._3NWXiW_Items li {
  margin: 0 0 10px;
  list-style-type: none;
}

.cDj1qq_LinkCreator {
  grid-gap: 30px;
  flex-direction: column;
  display: flex;
}

.cDj1qq_LinkCreator__actions {
  justify-content: flex-end;
  margin-top: 10px;
  display: flex;
}

.cDj1qq_Link {
  grid-gap: 5px;
  background: var(--gray1);
  cursor: pointer;
  border-radius: 5px;
  grid-template-rows: auto auto;
  grid-template-areas: "cDj1qq_label cDj1qq_scheme"
                       "cDj1qq_url cDj1qq_url";
  padding: 10px;
  display: grid;
  position: relative;
}

.cDj1qq_Link__scheme {
  grid-area: cDj1qq_scheme;
  margin-left: auto;
  font-size: 14px;
}

.cDj1qq_Link__label {
  font-size: 14px;
  font-weight: var(--bold);
  grid-area: cDj1qq_label;
}

.cDj1qq_Link__url {
  grid-area: cDj1qq_url;
  font-size: 12px;
  overflow: hidden;
}

.cDj1qq_Link:hover .cDj1qq_Link__urlHighlight {
  background: #0000000d;
}

.cDj1qq_Action {
  grid-gap: 10px;
  border: 3px solid var(--yellow);
  background: var(--white);
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
  display: flex;
}

.cDj1qq_Action__message {
  font-size: 20px;
  font-weight: bold;
}

._9uyHxa_CPCLinkCreator__result {
  margin-top: 30px;
}

._9uyHxa_Link {
  grid-gap: 5px;
  background: var(--gray1);
  cursor: pointer;
  border-radius: 5px;
  grid-template-rows: auto auto;
  grid-template-areas: "_9uyHxa_label"
                       "_9uyHxa_url";
  padding: 10px;
  display: grid;
  position: relative;
}

._9uyHxa_Link__label {
  font-size: 14px;
  font-weight: var(--bold);
  grid-area: _9uyHxa_label;
}

._9uyHxa_Link__url {
  grid-area: _9uyHxa_url;
  font-size: 20px;
}

._5dc2pW_MonthSelector {
  background: var(--white);
  font-family: inherit;
  font-size: 16px;
  font-weight: var(--bold);
  border: 1px solid var(--gray3);
  appearance: none;
  background-image: url("chevron-down.52252869.svg");
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 15px;
  border-radius: 5px;
  padding: 6px 36px 6px 12px;
}

._5dc2pW_MonthSelector:hover {
  border-color: var(--gray4);
  cursor: pointer;
}

.kO6EVa_SearchInspector h2 {
  margin-bottom: 1em;
}

.kO6EVa_SearchInspector section {
  margin-bottom: 50px;
}

.kO6EVa_ProductGrid {
  grid-gap: 40px 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
}

.kO6EVa_Product {
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-items: stretch;
  padding: 5px;
  display: flex;
  overflow: hidden;
}

.kO6EVa_Product__image img {
  aspect-ratio: .8;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.kO6EVa_Product__title {
  font-size: 15px;
  font-weight: var(--bold);
  margin-bottom: 10px;
  padding-top: 10px;
  text-decoration: none;
}

.kO6EVa_Product__image:hover + .kO6EVa_Product__title, .kO6EVa_Product__title:hover {
  text-decoration: underline;
}

.kO6EVa_Product__price {
  font-size: 13px;
  font-weight: var(--bold);
  margin-top: auto;
}

.dKSbzW_menu {
  height: 100%;
  width: var(--menu-width);
  position: fixed;
  top: 0;
  left: 0;
}

.dKSbzW_main {
  padding-left: var(--menu-width);
  min-height: 100%;
  flex-direction: column;
  display: flex;
}

.dKSbzW_QuickLinks {
  z-index: var(--index-quicklinks);
  grid-gap: 10px;
  font-size: 12px;
  display: flex;
  position: fixed;
  top: 5px;
  right: 10px;
}

.dKSbzW_QuickLinks--hidden {
  opacity: 0;
}

.dKSbzW_QuickLinks:hover {
  opacity: 1;
}

.dKSbzW_QuickLinks a {
  text-decoration: none;
}

.dKSbzW_QuickLinks a:hover {
  cursor: pointer;
  text-decoration: underline;
}

/*# sourceMappingURL=index.89d41d65.css.map */
